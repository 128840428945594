import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import { TbFilterSearch } from "react-icons/tb";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import AuthServices from "../../authServices/AuthServices";
import baseURL from "../../authServices/Url";
import Multiselect from "multiselect-react-dropdown";

function ManagePhysicalGold() {
  const [jewelleryModal, setJewelleryModal] = useState(false);
  const [img, setImg] = useState([]);
  const [video, setVideo] = useState("");
  const [productList, setProductList] = useState([]);
  const [allProductList, setALlProductList] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(true);
  const [productcertificate, setProductcertificate] = useState({});
  const [productCat, setProductCat] = useState({});
  const [productCategory, setProductCategory] = useState(setProductCat?.[0]?.id);
  const [sortedProducts, setSortedProducts] = useState([...allProductList]);
 const [sortOrder, setSortOrder] = useState("asc");
  const [metal, setMetal] = useState({});
  const [selectedProduct, setSelectedProduct] = useState();
  const [availability, setAvailability] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [offerData, setOfferData] = useState([]);
  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    productName: "",
    productCode: "",
    productHeight: "",
    stoneWeight: "",
    productDescription: "",
    grossWeight: "",
    netWeight: "",
    productImage: "",
    image360: "",
    productWidth: "",
    productDimensionUnit: "",
    wasteagePercentage: "",
    gender: "",
    productDiscount: "",
    // productAvailability:'',
    productMakingCharges: "",
    productStock: "",
    productGemstone: "",
    productOffers: [],
    productCertifications: [],
    productCategory: "",
    productMetal: "",
  });

  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    productName: "",
    productCode: "",
    productHeight: "",
    stoneWeight: "",
    productDescription: "",
    grossWeight: "",
    netWeight: "",
    productImage: "",
    image360: "",
    productWidth: "",
    productDimensionUnit: "",
    wasteagePercentage: "",
    gender: "",
    productDiscount: "",
    productAvailability: "",
    productMakingCharges: "",
    productStock: "",
    productGemstone: "",
    productOffers: "",
    productCertifications: "",
    productCategory: "",
    productMetal: "",
  });
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [weights, setWeights] = useState({});
  const [weightsArray, setWeightsArray] = useState([]);
  const [showJewel, setShowJewel] = useState(false);
  const [viewJewel, setViewJewel] = useState({});
  const [selectedOptionsCert, setSelectedOptionsCert] = useState([]);
  const [selectedOptionsCert1, setSelectedOptionsCert1] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [selectedOffers1, setSelectedOffers1] = useState([]);

  const vendorToken1 = localStorage.getItem("accessVendorToken");
  const vendorIdGold = localStorage.getItem("goldVendorId");
  
  const onSelect = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
    const updatedWeights = { ...weights };
    delete updatedWeights[removedItem?.gemstone_id];
    setWeights(updatedWeights);
  };

  const handleAdd = (e, gemstoneId) => {
    if (
      weightsArray?.length &&
      !weightsArray?.some((obj) => obj?.gemstone_id === gemstoneId)
    ) {
      const newWeightObj = {
        gemstone_id: gemstoneId,
        gemstone_weight: parseInt(e?.target?.value),
      };
      setWeightsArray([...weightsArray, newWeightObj]);
    } else {
      const newArray =
        weightsArray?.length > 0
          ? weightsArray?.filter((obj) => obj?.gemstone_id !== gemstoneId)
          : [];
      const newWeightObj = {
        gemstone_id: gemstoneId,
        gemstone_weight: parseInt(e?.target?.value),
      };
      setWeightsArray([...newArray, newWeightObj]);
    }
  };

  const onSelectCertificate = (selectedList, selectedItem) => {
    const selectedCertIds = selectedList?.map((item) => item?.id);
    setSelectedOptionsCert(selectedCertIds);
    setSelectedOptionsCert1(selectedList);
  };

  const onRemoveCertificate = (selectedList, removedItem) => {
    const updatedSelectedCertIds = selectedList?.map((item) => item?.id);
    setSelectedOptionsCert(updatedSelectedCertIds);
    setSelectedOptionsCert1(selectedList);
  };

  const onSelectOffer = (selectedList, selectedItem) => {
    const selectedCertIds = selectedList?.map((item) => item?.id);
    setSelectedOffers(selectedCertIds);
    setSelectedOffers1(selectedList);
  };

  const onRemoveOffer = (selectedList, removedItem) => {
    const updatedSelectedCertIds = selectedList?.map((item) => item?.id);
    setSelectedOffers(updatedSelectedCertIds);
    setSelectedOffers1(selectedList);
  };

  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e?.target?.productName]: e?.target?.value,
      [e?.target?.productCode]: e?.target?.value,
      [e?.target?.productHeight]: e?.target?.value,
      [e?.target?.stoneWeight]: e?.target?.value,
      [e?.target?.productDescription]: e?.target?.value,
      [e?.target?.grossWeight]: e?.target?.value,
      [e?.target?.netWeight]: e?.target?.value,
      [e?.target?.productImage]: e?.target?.value,
      [e?.target?.image360]: e?.target?.value,
      [e?.target?.productWidth]: e?.target?.value,
      [e?.target?.productDimensionUnit]: e?.target?.value,
      [e?.target?.wasteagePercentage]: e?.target?.value,
      [e?.target?.gender]: e?.target?.value,
      [e?.target?.productDiscount]: e?.target?.value,
      [e?.target?.productAvailability]: e?.target?.value,
      [e?.target?.productMakingCharges]: e?.target?.value,
      [e?.target?.productStock]: e?.target?.value,
      [e?.target?.productGemstone]: e?.target?.value,
      [e?.target?.productOffers]: e?.target?.value,
      [e?.target?.productCertifications]: e?.target?.value,
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e?.target?.productName]: null,
      [e?.target?.productHeight]: null,
      [e?.target?.productCode]: null,
      [e?.target?.stoneWeight]: null,
      [e?.target?.productDescription]: null,
      [e?.target?.grossWeight]: null,
      [e?.target?.netWeight]: null,
      [e?.target?.productImage]: null,
      [e?.target?.image360]: null,
      [e?.target?.productWidth]: null,
      [e?.target?.productDimensionUnit]: null,
      [e?.target?.wasteagePercentage]: null,
      [e?.target?.gender]: null,
      [e?.target?.productDiscount]: null,
      [e?.target?.productAvailability]: null,
      [e?.target?.productMakingCharges]: null,
      [e?.target?.productStock]: null,
      [e?.target?.productGemstone]: null,
      [e?.target?.productOffers]: null,
      [e?.target?.productCertifications]: null,
    });
  };

  const handleMetalChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      productMetal: e?.target?.value,
    });
  };

  const handleCategoryChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      productCategory: e.target.value,
    });
  };
  const handleCategoryChange1 = (e) => {
    setProductCategory(e.target.value);
    setShowAllProducts(false); 
  };

  const handleGenderChange = (e) => {
    setPhysicalGoldForm({ ...physicalGoldForm, gender: e.target.value });
  };

  const handleDimesionChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      productDimensionUnit: e.target.value,
    });
  };

  useEffect(() => {
    setSortedProducts([...allProductList]);
  }, [allProductList]);

  const handleSort = () => {
    const sorted = [...sortedProducts].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.product_name.localeCompare(b.product_name);
      } else {
        return b.product_name.localeCompare(a.product_name);
      }
    });
    setSortedProducts(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  

  const handleValidation = () => {
    const {
      productName,
      productCode,
      productHeight,
      stoneWeight,
      productDescription,
      grossWeight,
      netWeight,
      productImage,
      image360,
      productWidth,
      productDimensionUnit,
      wasteagePercentage,
      gender,
      productDiscount,
      productAvailability,
      productMakingCharges,
      productStock,
      productGemstone,
      productMetal,
      productOffers,
      productCertifications,
      productCategory,
    } = physicalGoldForm;

    const newErrors = {};

    if (!productName) {
      newErrors.productName = "please enter product Name";
    }
    if (!productMetal) {
      newErrors.productMetal = "please select Metal";
    }
    if (!productCode) {
      newErrors.productCode = "please enter product Code";
    }
    if (availability === false) {
      newErrors.availability = "please select availability";
    }
    if (!productHeight) {
      newErrors.productHeight = "please enter product Height";
    }
    if (!stoneWeight) {
      newErrors.stoneWeight = "please enter Stone Weight";
    }
    if (!productDescription) {
      newErrors.productDescription = "please enter Product Description";
    }
    if (!grossWeight) {
      newErrors.grossWeight = "please enter Gross Weight";
    }
    if (!netWeight) {
      newErrors.netWeight = "please enter net Weight of product";
    }
    if (!img) {
      newErrors.productImage = "please upload Product Image";
    }
    if (!video) {
      newErrors.image360 = "please upload 360 degree view of jewel";
    }
    if (!productWidth) {
      newErrors.productWidth = "please enter Product Width";
    }
    if (!productDimensionUnit) {
      newErrors.productDimensionUnit = "please enter Product Dimensions";
    }
    if (!wasteagePercentage) {
      newErrors.wasteagePercentage = "please enter wastage percentage";
    }
    if (!gender) {
      newErrors.gender = "please enter gender";
    }
    if (!productDiscount) {
      newErrors.productDiscount = "please enter Product Discount";
    }
    if (!productAvailability) {
      newErrors.productAvailability = "please enter availability";
    }
    if (!productMakingCharges) {
      newErrors.productMakingCharges = "please enter Product Making Charges";
    }
    if (!productStock) {
      newErrors.productStock = "please enter Product Stock";
    }
    if (!weightsArray) {
      newErrors.productGemstone = "please select Product Gemstone";
    }
    if (!productOffers) {
      newErrors.productOffers = "please upload Produc tOffers";
    }
    if (!productcertificate) {
      newErrors.productCertifications = "please select Product Certifications";
    }
    if (!productCategory) {
      newErrors.productCategory = "please select Product Category";
    }
    return newErrors;
  };
  const handleImageChange = (e) => {
    const image = e.target.files;
    console.log("@@@@@@@@@@@@@@@", image?.length);
    setImg([...image]);
  };

  const productImagesUpload = async (id) => {
    try {
      const handleValidationObject = handleValidation();
      if (Object.keys(handleValidationObject).length > 3) {
        setPhysicalGoldFormErrors(handleValidationObject);
        console.log("errrrrr");
      } else {
        var formdata = new FormData();
        for (let i = 0; i < img?.length; i++) {
          formdata?.append("images", img[i]);
        }
        if (video !== undefined) {
          formdata.append("images", video);
        }
        // formdata.append("image", img);
        console.log("image userCredentials#############", formdata, img, video);
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
          },
        };

        const imgUpload = await axios.post(
          `https://aunest-backend.moshimoshi.cloud/api/v1/mediaMingle/upload/multiple`,
          formdata,
          requestOptions
        );
        console.log("imgUpload imgUpload imgUpload", imgUpload);
        const extensions = imgUpload?.data?.data?.url?.map((x) =>
          x?.split(".").pop()
        );
        console.log(
          "@############@@@@@@@@",
          imgUpload?.data?.data?.url?.slice(0, extensions.indexOf("mp4")),
          imgUpload?.data?.data?.url[extensions.indexOf("mp4")]
        );
        if (imgUpload?.status === 200) {
          console.log("selectedProduct?.id", imgUpload?.data?.data?.url);
          id
            ? handleSubmitEdit({
                id,
                images: imgUpload?.data?.data?.url?.slice(
                  0,
                  extensions.indexOf("mp4")
                ),
                video: imgUpload?.data?.data?.url[extensions.indexOf("mp4")],
              })
            : handleSubmit({
                images: imgUpload?.data?.data?.url?.slice(
                  0,
                  extensions.indexOf("mp4")
                ),
                video: imgUpload?.data?.data?.url[extensions.indexOf("mp4")],
              });
        }
      }
    } catch (err) {
      console.log("err0r ==>", err);
    }
  };

  const handleSubmit = async (props) => {
  
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        "Content-Type": "application/json",
      },
    };
    const userCredentials = {
      product_name: physicalGoldForm?.productName,
      stone_weight: parseInt(physicalGoldForm?.stoneWeight),
      product_description: physicalGoldForm?.productDescription,
      gross_weight: parseInt(physicalGoldForm?.grossWeight),
      net_weight: parseInt(physicalGoldForm?.netWeight),
      product_height: parseInt(physicalGoldForm?.productHeight),
      product_image: props?.images,
      product_360_view: props?.video,
      product_code: physicalGoldForm?.productCode,
      product_width: parseInt(physicalGoldForm?.productWidth),
      product_dimension_unit: physicalGoldForm?.productDimensionUnit,
      wasteage_percentage: parseInt(physicalGoldForm?.wasteagePercentage),
      gender: physicalGoldForm?.gender,
      product_making_charges: parseInt(physicalGoldForm?.productMakingCharges),
      product_discount: parseInt(physicalGoldForm?.productDiscount),
      product_stock: parseInt(physicalGoldForm?.productStock),
      product_availability: availability,
      metal_id: physicalGoldForm?.productMetal,
      gemstones: weightsArray,
      // 'product_offers': [],
      product_offers: selectedOffers,
      product_certifications: selectedOptionsCert,
      product_category_id: physicalGoldForm?.productCategory,
    };

    const createProduct = await axios.post(
      `https://aunest-backend.moshimoshi.cloud/api/v1/product/createProduct`,
      userCredentials,
      requestOptions
    );
    console.log("createProduct createProduct", createProduct);

    if (createProduct?.status === 200) {
      setPhysicalGoldForm({
        ...physicalGoldForm,
        productName: "",
        productCode: "",
        productHeight: "",
        stoneWeight: "",
        productDescription: "",
        grossWeight: "",
        netWeight: "",
        productImage: "",
        image360: "",
        productWidth: "",
        productDimensionUnit: "",
        wasteagePercentage: "",
        gender: "",
        productMakingCharges: "",
        productDiscount: "",
        productStock: "",
        productAvailability: "",
        productOffers: "",
        productCertifications: "",
      });
      handleCloseModal();
      setSelectedOptions();
      setSelectedOptionsCert();
      setSelectedOptionsCert1();
      setSelectedOffers1();
      setSelectedOffers();
      setWeightsArray();
      setWeights();
      setOptions();
    } else {
      console.log("error", createProduct);
    }
  };

  const handleSubmitEdit = async (props) => {
    
    const requestOptions = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        "Content-Type": "application/json",
      },
    };
    const userCredentials = {
      product_name: physicalGoldForm?.productName,
      stone_weight: parseInt(physicalGoldForm?.stoneWeight),
      product_description: physicalGoldForm?.productDescription,
      gross_weight: parseInt(physicalGoldForm?.grossWeight),
      net_weight: parseInt(physicalGoldForm?.netWeight),
      product_height: parseInt(physicalGoldForm?.productHeight),
      // 'product_image':physicalGoldForm?.productImage,
      // 'product_360_view':physicalGoldForm?.image360,
      product_image: [...props?.images, ...selectedProduct?.product_image],
      product_360_view: props.video,
      product_code: physicalGoldForm?.productCode,
      product_width: parseInt(physicalGoldForm?.productWidth),
      product_dimension_unit: physicalGoldForm?.productDimensionUnit,
      wasteage_percentage: parseInt(physicalGoldForm?.wasteagePercentage),
      gender: physicalGoldForm?.gender,
      product_making_charges: parseInt(physicalGoldForm?.productMakingCharges),
      product_discount: parseInt(physicalGoldForm?.productDiscount),
      product_stock: parseInt(physicalGoldForm?.productStock),
      product_availability: availability,
      metal_id: physicalGoldForm?.productMetal?.id,
      gemstones: weightsArray,
      // 'product_offers': [],
      product_offers: selectedOffers,
      product_certifications: selectedOptionsCert,
      product_category_id: physicalGoldForm?.productCategory,
      gold_vendor_id: vendorIdGold,
      product_id: props?.id,
    };

    const editProduct = await axios.patch(
      `https://aunest-backend.moshimoshi.cloud/api/v1/product/updateProduct`,
      userCredentials,
      requestOptions
    );
    console.log("editProduct editProduct", editProduct);

    if (editProduct?.status === 200) {
      toast.success(`Product succesfully edited`);
      productsAvailable();
      setPhysicalGoldForm({
        ...physicalGoldForm,
        productName: "",
        productCode: "",
        productHeight: "",
        stoneWeight: "",
        productDescription: "",
        grossWeight: "",
        netWeight: "",
        productImage: "",
        image360: "",
        productWidth: "",
        productDimensionUnit: "",
        wasteagePercentage: "",
        gender: "",
        productMakingCharges: "",
        productDiscount: "",
        productStock: "",
        productAvailability: "",
        productOffers: "",
        productCertifications: "",
      });
      handleCloseModal();
      setSelectedOptions();
      setSelectedOptionsCert();
      setSelectedOptionsCert1();
      setSelectedOffers1();
      setSelectedOffers();
      setWeightsArray();
      setWeights();
      setOptions();
    } else {
      console.log("error", editProduct);
    }
    // }
  };


  const getProductCertificates = async () => {
    const productCertif = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/productCertification`
    );
    if (productCertif.status === 200) {
      setProductcertificate(productCertif?.data?.data);
    } else {
      console.log("error");
    }
  };

  const gemStoneAvailable = async () => {
    const getGemstone = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/gemstone/readGemstone?gold_vendor_id=${vendorIdGold}`
    );
    if (getGemstone?.status === 200) {
      setOptions(getGemstone?.data?.data);
    } else {
      console.log("error");
    }
  };
  const getProductCategories = async () => {
    const getCategories = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/readProductCategory?gold_vendor_id=${vendorIdGold}`
    );
    if (getCategories?.status === 200) {
      setProductCat(getCategories?.data?.data);
    }
  };
  const metalsAvailable = async () => {
    const getMetal = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/metal/readMetal?gold_vendor_id=${vendorIdGold}`
    );
    if (getMetal?.status === 200) {
      setMetal(getMetal?.data?.data);
    } else {
      console.log("error");
    }
  };
  const productsAvailable = async () => {
    const getProducts = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/product/readProduct?product_category_id=${productCategory}&gold_vendor_id=${vendorIdGold}`
    );
    console.log("getProducts", getProducts);
    if (getProducts?.status === 200) {
      setProductList(getProducts?.data?.data);
    } else {
      console.log("error", getProducts);
    }
  };

  const productsAvailableBasedOnVendor = async () => {
    const getAllProducts = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/product/readProductAll?gold_vendor_id=${vendorIdGold}`
    );
    console.log("getProducts", getAllProducts);
    if (getAllProducts?.status === 200) {
      setALlProductList(getAllProducts?.data);
    } else {
      console.log("error", getAllProducts);
    }
  };

  const getProductOffer = async () => {
    const getOffer = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/productOffer?gold_vendor_id=${vendorIdGold}`
    );
    console.log("gggggggggggggggetOffer", getOffer);
    if (getOffer?.status === 200) {
      setOfferData(getOffer?.data?.data);
    }
  };

  useEffect(() => {
    getProductCertificates();
    getProductCategories();
    gemStoneAvailable();
    metalsAvailable();
    getProductOffer();
    productsAvailableBasedOnVendor();
  }, []);

  const handleShowModalDelete = (prod) => {
    setDeleteId(prod?.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    productsAvailable();
  }, [productCategory]);

  const inACtivateProduct = async (prod) => {
    console.log("ppppppppprod", prod);
    const userCredentials = {
      gold_vendor_id: vendorIdGold,
      product_category_id: "",
    };
    const delProduct = await axios.delete(
      `https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/deleteProductCategoryPermnt`,
      userCredentials
    );
    console.log("delProduct delProduct", delProduct);
  };

  const handleCloseModal = () => setJewelleryModal(false);
  const handleShowModal = (prod) => {
    if (!prod) {
      return; // Exit early if prod is undefined or null
    }
  
    setSelectedProduct(prod);
    setJewelleryModal(true);
  
    // Check if product_availability is defined before setting availability
    if (prod.product_availability) {
      setAvailability(prod.product_availability);
    }
  
    // Check if product_certifications is defined before setting selected options
    if (prod.product_certifications) {
      setSelectedOptionsCert1(prod.product_certifications);
    }
  
    // Check if gemstones and product_offers are defined before setting selected options and offers
    if (prod.gemstones) {
      setSelectedOptions(prod.gemstones.map((option) => option?.gemstone));
    }
    if (prod.product_offers) {
      setSelectedOffers1(prod.product_offers);
    }
  
    // Check if gemstones is defined before setting weights array
    if (prod.gemstones) {
      setWeightsArray(
        prod.gemstones.map((option) => ({
          gemstone_id: option?.gemstone_id,
          gemstone_weight: option?.gemstone_weight,
        }))
      );
    }
  
    // Set other form fields with null checks
    setPhysicalGoldForm({
      productName: prod.product_name || "",
      productCode: prod.product_code || "",
      productHeight: prod.product_height || "",
      stoneWeight: prod.stone_weight || "",
      productDescription: prod.product_description || "",
      grossWeight: prod.gross_weight || "",
      netWeight: prod.net_weight || "",
      productImage: prod.product_image || "",
      image360: prod.product_360_view || "",
      productWidth: prod.product_width || "",
      productDimensionUnit: prod.product_dimension_unit || "",
      wasteagePercentage: prod.wasteage_percentage || "",
      gender: prod.gender || "",
      productDiscount: prod.product_discount || "",
      productMakingCharges: prod.product_making_charges || "",
      productStock: prod.product_stock || "",
      productOffers: prod.product_offers || [],
      productCategory: prod.product_category?.id || "",
      productMetal: prod.metal?.id || "",
    });
  };
  

  const handleShowAllProductsModal = (prod) => {
    if (!prod || typeof prod !== 'object') {
      return; 
    }
  
    // Destructure the properties from prod
    const {
      product_availability,
      product_certifications,
      gemstones,
      product_offers,
      product_name,
      product_code,
      product_height,
      stone_weight,
      product_description,
      gross_weight,
      net_weight,
      product_image,
      product_360_view,
      product_width,
      product_dimension_unit,
      wasteage_percentage,
      gender,
      product_discount,
      product_making_charges,
      product_stock,
      product_category,
      metal
    } = prod;
  
    // Now, you can safely access the properties without encountering the error
    setSelectedProduct(prod);
    setJewelleryModal(true);
    setAvailability(product_availability);
    setSelectedOptionsCert1(product_certifications);
    setSelectedOptions(gemstones?.map(option => option?.gemstone));
    setSelectedOffers1(product_offers);
    setWeightsArray(gemstones?.map(option => ({
      gemstone_id: option?.gemstone_id,
      gemstone_weight: option?.gemstone_weight
    })));
  
    setPhysicalGoldForm({
      productName: product_name || "",
      productCode: product_code || "",
      productHeight: product_height || "",
      stoneWeight: stone_weight || "",
      productDescription: product_description || "",
      grossWeight: gross_weight || "",
      netWeight: net_weight || "",
      productImage: product_image || "",
      image360: product_360_view || "",
      productWidth: product_width || "",
      productDimensionUnit: product_dimension_unit || "",
      wasteagePercentage: wasteage_percentage || "",
      gender: gender || "",
      productDiscount: product_discount || "",
      productMakingCharges: product_making_charges || "",
      productStock: product_stock || "",
      productOffers: product_offers || [],
      productCategory: product_category?.id || "",
      productMetal: metal?.id || ""
    });
  };
  
  
  
  
  const handleShowModal2 = (prod) => {
    console.log("@@@@@@@@@@@@@prod", prod);
    setShowJewel(true);
    setViewJewel(prod);
  };
  const handleCloseModal2 = () => setShowJewel(false);

  const permanentDeleteProduct = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${vendorToken1}`,
      },
    };
    const delProduct = await axios.delete(
      `https://aunest-backend.moshimoshi.cloud/api/v1/product/deleteProduct?product_id=${deleteId}`,
      requestOptions
    );
    if (delProduct?.status === 200) {
      setIsOpen(false);
      getProductCategories();
      toast.success(`Product succesfully got deleted`);
    } else {
      console.log("error", delProduct?.message);
    }
  };

  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Physical Gold</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={4}>
                  <h3 className="headertext">Manage Physical Gold:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Add Jewellery
                  </Button>
                </Col>
              </Row>

              <hr />
              <Card className="p-2">
                <h3 className="text1">Available Products:</h3>
                <Row style={{ marginBlock: "10px" }}>
                  <Col md={3}>
                    <Form.Select
                      aria-label="select Product Category"
                      size={"sm"}
                      className="selectsizesmall"
                      name="productCategory"
                      onChange={handleCategoryChange1}
                    >
                      <option>Select Product Category</option>
                      {productCat?.length &&
                        productCat?.map((prod, i) => {
                          return (
                            <option name="productCategory" value={prod?.id}>
                              {prod?.category_name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>

                {showAllProducts
                  ? Array.isArray(allProductList) &&
                    allProductList.length > 0 && (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name  <TbFilterSearch onClick={handleSort} style={{ cursor: "pointer", marginLeft: "8px" }} /></th>
                            <th>Product Image</th>
                            <th>Product Code</th>
                            <th>Gender</th>
                            <th>Product Availability</th>
                            {/* <th>Product Discount</th> */}
                            <th>Product Stock</th>
                            {/* <th>Price</th> */}
                            {/* <th>View</th> */}
                            {/* <th>Edit</th>
                            <th>Delete</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {sortedProducts.map((prod, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{prod?.product_name}</td>
                              <td>
                                <Figure>
                                  <Figure.Image
                                    width={100}
                                    height={150}
                                    src={prod?.product_image[0]}
                                  />
                                </Figure>
                              </td>
                              <td>{prod?.product_code}</td>
                              <td>{prod?.gender}</td>
                              <td>{prod?.product_availability.toString()}</td>
                              {/* <td>{prod?.product_discount}</td> */}
                              <td>{prod?.product_stock}</td>
                              {/* <td>{prod?.price_breakup?.total_price}</td> */}
                              {/* <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModal2(prod)}
                                  icon={faEye}
                                  className="editIcon"
                                />
                              </td> */}
                              {/* <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModal(prod)}
                                  icon={faEdit}
                                  className="editIcon"
                                />
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModalDelete(prod)}
                                  icon={faTrash}
                                  className="editIcon"
                                />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )
                  : productCategory &&
                    Array.isArray(productList) &&
                    productList.length > 0 && (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Product Image</th>
                            <th>Product Code</th>
                            <th>Gender</th>
                            <th>Product Availability</th>
                            <th>Product Discount</th>
                            <th>Product Stock</th>
                            <th>Price</th>
                            <th>View</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.map((prod, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{prod?.product_name}</td>
                              <td>
                                <Figure>
                                  <Figure.Image
                                    width={100}
                                    height={150}
                                    src={prod?.product_image[0]}
                                  />
                                </Figure>
                              </td>
                              <td>{prod?.product_code}</td>
                              <td>{prod?.gender}</td>
                              <td>{prod?.product_availability.toString()}</td>
                              <td>{prod?.product_discount}</td>
                              <td>{prod?.product_stock}</td>
                              <td>{prod?.price_breakup?.total_price}</td>
                              <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModal2(prod)}
                                  icon={faEye}
                                  className="editIcon"
                                />
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModal(prod)}
                                  icon={faEdit}
                                  className="editIcon"
                                />
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  onClick={() => handleShowModalDelete(prod)}
                                  icon={faTrash}
                                  className="editIcon"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add/Edit Jewellery Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name="productName"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  required
                  value={physicalGoldForm?.productName}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productName}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>product Code</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter product Code"
                  size="sm"
                  name="productCode"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productCode}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productCode}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Height</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Product Height"
                  size="sm"
                  name="productHeight"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productHeight}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productHeight}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Width</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Product Width"
                  size="sm"
                  name="productWidth"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productWidth}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productWidth}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Dimension Unit</Form.Label>

                <Form.Select
                  aria-label="select Product Dimension Unit"
                  size={"sm"}
                  className="selectsizesmall"
                  name="productDimensionUnit"
                  onChange={handleDimesionChange}
                  value={physicalGoldForm?.productDimensionUnit}
                >
                  <option>Select Dimension</option>
                  <option name="CM" value="CM">
                    CM
                  </option>
                  <option name="MM" value="MM">
                    MM
                  </option>
                  <option name="IN" value="IN">
                    IN
                  </option>
                </Form.Select>
                <span className="text-danger">
                  {physicalGoldFormErrors?.productDimensionUnit}
                </span>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Gender</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Gender"
                    size={"sm"}
                    className="selectsizesmall"
                    name="handleGenderChange"
                    onChange={handleGenderChange}
                    value={physicalGoldForm?.gender}
                  >
                    <option>Select Gender</option>
                    <option name="Female" value="FEMALE">
                      Female
                    </option>
                    <option name="Male" value="MALE">
                      Male
                    </option>
                  </Form.Select>
                </div>
                <span className="text-danger">
                  {physicalGoldFormErrors?.gender}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Product Offers</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Multiselect
                    options={offerData}
                    displayValue="offer_name"
                    onSelect={onSelectOffer}
                    onRemove={onRemoveOffer}
                    selectedValues={selectedOffers1}
                    placeholder="Select Offers"
                    value={selectedOptionsCert}
                  />
                </div>
                <span className="text-danger">
                  {physicalGoldFormErrors?.productOffers}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Metal</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Metal"
                    size={"sm"}
                    className="selectsizesmall"
                    name="metal"
                    onChange={handleMetalChange}
                    value={physicalGoldForm?.productMetal}
                  >
                    <option>Select Metal </option>
                    {metal.length &&
                      metal?.map((metal, i) => {
                        // console.log('metalllllllllll',metal)
                        return (
                          <option name="metal" value={metal?.id}>
                            {metal?.metal_name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
                <span className="text-danger">
                  {physicalGoldFormErrors?.productMetal}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select gemstones</Form.Label>
                <Multiselect
                  options={options}
                  displayValue="gemstone_name"
                  onSelect={onSelect}
                  onRemove={onRemove}
                  selectedValues={selectedOptions}
                  placeholder="Select options"
                  // value={}
                />
                <span className="text-danger">
                  {!weightsArray && "please select gemstone"}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              {selectedOptions?.map((gem, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div md={2}>{gem?.gemstone_name}(weight)</div>
                    <input
                      md={2}
                      type="number"
                      placeholder="Enter weight"
                      value={
                        weightsArray?.find((x) => x?.gemstone_id === gem.id)
                          ?.gemstone_weight || ""
                      }
                      onChange={(e) => handleAdd(e, gem?.id)}
                    />
                    {/* <Button md={2} onClick={() => handleAdd(gem?.id)} variant="warning">add</Button> */}
                  </div>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Gross Weight</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Gross Weight"
                  size="sm"
                  name="grossWeight"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.grossWeight}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.grossWeight}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Stone Weight</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Ston Weight"
                  size="sm"
                  name="stoneWeight"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.stoneWeight}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.stoneWeight}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Net Weight</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Net Weight"
                  size="sm"
                  name="netWeight"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.netWeight}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.netWeight}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Wastage %</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Wastage Percentage"
                  size="sm"
                  name="wasteagePercentage"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.wasteagePercentage}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.wasteagePercentage}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Product Category</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Product Category"
                    size={"sm"}
                    className="selectsizesmall"
                    name="productCategory"
                    onChange={handleCategoryChange}
                    value={physicalGoldForm?.productCategory}
                  >
                    <option>Select Product Category</option>
                    {productCat?.length &&
                      productCat?.map((prod, i) => {
                        return (
                          <option name="productCategory" value={prod?.id}>
                            {prod?.category_name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
                <span className="text-danger">
                  {physicalGoldFormErrors?.productCategory}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Product Certifications</Form.Label>
                <Multiselect
                  options={productcertificate}
                  displayValue="product_certification_name"
                  onSelect={onSelectCertificate}
                  onRemove={onRemoveCertificate}
                  selectedValues={selectedOptionsCert1}
                  placeholder="Select certificates"
                  value={selectedOptionsCert}
                />
                <span className="text-danger">
                  {selectedOptionsCert1 &&
                    physicalGoldFormErrors?.productCertifications}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Making Charges /grm</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Product Making Charges"
                  size="sm"
                  name="productMakingCharges"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productMakingCharges}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productMakingCharges}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Discount</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Product Discount"
                  size="sm"
                  name="productDiscount"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productDiscount}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productDiscount}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Stock</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="number"
                  placeholder="Enter Product Stock"
                  size="sm"
                  name="productStock"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={physicalGoldForm?.productStock}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productStock}
                </span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label></Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Product Availability"
                  checked={availability}
                  onChange={(e) => setAvailability(e?.target?.checked)}
                  value={availability}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.availability}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Add Product Image</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  placeholder="Product Image"
                  className="mb-3"
                  name="productImage"
                  onChange={(e) => handleImageChange(e)}
                  autoComplete="off"
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productImage}
                </span>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Add product_360_view Vedio(mp4)</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Product View"
                  className="mb-3"
                  name="image360"
                  onChange={(e) => setVideo(e?.target?.files[0])}
                  autoComplete="off"
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.image360}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Product Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="enter Product Description"
                  className="mb-3"
                  name="productDescription"
                  onChange={handleChange}
                  autoComplete="off"
                  value={physicalGoldForm?.productDescription}
                />
                <span className="text-danger">
                  {physicalGoldFormErrors?.productDescription}
                </span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          {/* selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}> */}
          <Button
            variant="warning"
            onClick={() => {
              selectedProduct?.id
                ? productImagesUpload(selectedProduct?.id)
                : productImagesUpload();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}
      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the product ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          {/* <Button variant="warning" onClick={inACtivateProduct}>Inactivate</Button> */}
          <Button variant="danger" onClick={permanentDeleteProduct}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showJewel}
        onHide={handleCloseModal2}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Product Details:</h4>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              height: "150px",
              display: "flex",
              justifyContent: "space-evenly",
              overflowX: "scroll",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "150px",
                width: "150px",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #00183f",
              }}
            >
              <video
                src={viewJewel?.product_360_view}
                width="150"
                height="150"
                controls
              ></video>
            </div>
            {/* <Slider {...settings} > */}
            {viewJewel?.product_image?.length &&
              viewJewel?.product_image?.map((x, i) => {
                // console.log('xxxxxxxxx', x)
                return (
                  <div
                    style={{
                      display: "flex",
                      height: "150px",
                      width: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={x}
                      alt="product image"
                      width="150px"
                      height="150px"
                      style={{ border: "1px solid #00183f" }}
                    />
                  </div>
                );
              })}

            {/* </Slider> */}
          </div>
          <div>
            <p>Product certificates:</p>

            <div
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                justifyContent: "space-evenly",
                overflowX: "scroll",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {viewJewel?.product_certifications?.length &&
                viewJewel?.product_certifications?.map((cert, i) => {
                  console.log("certttttt", cert);
                  return (
                    <div
                      style={{
                        display: "flex",
                        height: "200px",
                        width: "200px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={cert?.product_certification_image}
                        alt="product image"
                        width="100px"
                        height="150px"
                      />
                      <p className="bodytext-color">
                        {cert?.product_certification_name}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <p className="headertext headertext">
              Product Name:
              <span className="bodytext-color">{viewJewel?.product_name}</span>
            </p>
            <p className="headertext headertext">
              Product Code:
              <span className="bodytext-color">{viewJewel?.product_code}</span>
            </p>
            <p className="headertext headertext">
              Product Height:
              <span className="bodytext-color">
                {viewJewel?.product_height}
              </span>
            </p>
            <p className="headertext headertext">
              Product Width:
              <span className="bodytext-color">{viewJewel?.product_width}</span>
            </p>
            <p className="headertext headertext">
              Product Dimension Uint:
              <span className="bodytext-color">
                {viewJewel?.product_dimension_unit}
              </span>
            </p>
            <p className="headertext headertext">
              Product Availability:
              <span className="bodytext-color">
                {viewJewel?.product_availability?.toString()}
              </span>
            </p>
            <p className="headertext headertext">
              Product Stock:
              <span className="bodytext-color">{viewJewel?.product_stock}</span>
            </p>
            <p className="headertext headertext">
              Product Category:
              <span className="bodytext-color">
                {viewJewel?.product_category?.category_name}
              </span>
            </p>
            <p className="headertext headertext">
              Product metal:
              <span className="bodytext-color">
                {viewJewel?.metal?.metal_name}
              </span>
            </p>
            <p className="headertext headertext">
              Gemstones:
              {viewJewel?.gemstones?.map((gem) => {
                return (
                  <p className="bodytext-color">
                    {gem?.gemstone?.gemstone_name}
                  </p>
                );
              })}
            </p>
            <p className="headertext headertext">
              Stone Weight :
              <span className="bodytext-color">{viewJewel?.stone_weight}</span>
            </p>
            <p className="headertext headertext">
              gender :
              <span className="bodytext-color">{viewJewel?.gender}</span>
            </p>
            <p className="headertext headertext">
              Product Discount:
              <span className="bodytext-color">
                {viewJewel?.product_discount}
              </span>
            </p>
            <p className="headertext headertext">
              Product Making Charges:
              <span className="bodytext-color">
                {viewJewel?.product_making_charges}
              </span>
            </p>
            <p className="headertext headertext">
              Product Wasteage Percentage:
              <span className="bodytext-color">
                {viewJewel?.wasteage_percentage}
              </span>
            </p>
            <p className="headertext headertext">
              Product Description:
              <span className="bodytext-color">
                {viewJewel?.product_description}
              </span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal2} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ManagePhysicalGold;
