import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { toast } from "react-toastify";
import AuthServices from '../../authServices/AuthServices'
import baseURL from '../../authServices/Url';

function ManageGemstone() {
  const [gemstoneModal, setGemstoneModal] = useState(false)
  const [productList, setProductList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('')
  const [img, setImg] = useState("");

  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    name: selectedProduct?.name,
    symbol: selectedProduct?.symbol,
    description: selectedProduct?.name,
    color: selectedProduct?.name,
    cut: selectedProduct?.name,
    priceUnit: selectedProduct?.name,
    clarity: selectedProduct?.name,
    image: selectedProduct?.name,
    weightUnit: '',
  })

  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    symbol: '',
    description: '',
    color: '',
    cut: '',
    priceUnit: '',
    clarity: '',
    image: '',
    weightUnit: ''
  })

  const vendorToken1 = localStorage.getItem('accessVendorToken');
  const vendorIdGold = localStorage.getItem('goldVendorId');
  console.log('vendorToken1 vendorToken1', vendorToken1);
  console.log('vendorIdGold', vendorIdGold);

  const gemStoneAvailable = async () => {
    const getGemstone = await axios.get(`https://aunest-backend.moshimoshi.cloud/api/v1/gemstone/readGemstone?gold_vendor_id=${vendorIdGold}`)
    console.log('getGemstone@@@@@@@@@@@@@@@@@@@', getGemstone)
    if (getGemstone?.status === 200) {
      setProductList(getGemstone?.data?.data)
    } else {
      console.log('error')
    }
  }
  useEffect(() => {
    gemStoneAvailable();
  }, [])

  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.symbol]: e.target.value,
      [e.target.description]: e.target.description,
      [e.target.color]: e.target.value,
      [e.target.clarity]: e.target.value,
      [e.target.priceUnit]: e.target.value,
      [e.target.cut]: e.target.value,
      [e.target.image]: e.target.value,
      [e.target.weightUnit]: e.target.value
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.symbol]: null,
      [e.target.color]: null,
      [e.target.clarity]: null,
      [e.target.priceUnit]: null,
      [e.target.cut]: null,
      [e.target.image]: null,
      [e.target.weightUnit]: null,
    });
  };

  const handleValidation = () => {
    const { name, symbol, description, weightUnit, color, clarity, priceUnit, cut, } = physicalGoldForm
    const newErrors = {}

    if (!name) {
      newErrors.name = 'please enter name'
    }
    if (!symbol) {
      newErrors.symbol = 'please enter symbol'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    if (!color) {
      newErrors.color = 'please enter color'
    }
    if (!clarity) {
      newErrors.clarity = 'please enter clarity'
    }
    if (!cut) {
      newErrors.cut = 'please enter cut'
    }
    if (!img) {
      newErrors.image = 'please upload image'
    }
    if (!priceUnit) {
      newErrors.priceUnit = 'please enter Price/Unit'
    }
    if (!weightUnit) {
      newErrors.weightUnit = 'please enter Price/Unit'
    }

    return newErrors
  }

  const gemstoneImageUpload = async () => {

    try {

      var formdata = new FormData();
      formdata.append("image", img);
      console.log('image userCredentials', img)
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
        },

      };

      const imgUpload = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/mediaMingle/upload`, formdata, requestOptions)
      console.log('imgUpload imgUpload imgUpload', imgUpload)
      if (imgUpload?.status === 200) {
        console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
        selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
      }
    } catch (err) {
      console.log('err0r ==>', err)
    }
  }
  const handleSubmit = async (url) => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = {
        'gemstone_name': physicalGoldForm?.name,
        'gemstone_symbol': physicalGoldForm?.symbol,
        'gemstone_description': physicalGoldForm?.description,
        'gemstone_color': physicalGoldForm?.color,
        'gemstone_cut': physicalGoldForm?.cut,
        'gemstone_clarity': physicalGoldForm?.clarity,
        'gemstone_image': url,
        'gemstone_price_per_weight_unit': physicalGoldForm?.priceUnit,
        "gemstone_weight_unit": "GRAMS",
      };

      const createGemstone = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/gemstone/createGemstone`, userCredentials, requestOptions)
      console.log('createGemstone createGemstone', createGemstone);
      if (createGemstone?.status === 200) {
        gemStoneAvailable()
        setPhysicalGoldForm({
          ...physicalGoldForm,
          name: '',
          symbol: '',
          description: '',
          color: '',
          clarity: '',
          priceUnit: '',
        })
        handleCloseModal()
        toast.success('Gemstone has successfully created')
      } else {
        console.log(createGemstone, "error after submit")
        toast.show({
          type: 'error',
          text1: `${createGemstone.message}`,
        })
        handleCloseModal()
      }
    }
  }

  const handleSubmitEdit = async (url) => {

    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {

      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = {
        'gemstone_name': physicalGoldForm?.name,
        'gemstone_symbol': physicalGoldForm?.symbol,
        'gemstone_description': physicalGoldForm?.description,
        'gemstone_color': physicalGoldForm?.color,
        'gemstone_cut': physicalGoldForm?.cut,
        'gemstone_clarity': physicalGoldForm?.clarity,
        'gemstone_image': url,
        'gemstone_price_per_weight_unit': physicalGoldForm?.priceUnit,
        'gemstone_id': selectedProduct?.id,
        'gemstone_weight_unit': physicalGoldForm?.weightUnit
      };

      const editGemstone = await axios.put(`https://aunest-backend.moshimoshi.cloud/api/v1/gemstone/updateGemstone`, userCredentials, requestOptions)
      console.log('editGemstone editGemstone', editGemstone);
      if (editGemstone?.status === 200) {
        toast.success('Gemstone has successfully updated')
        gemStoneAvailable()
        setPhysicalGoldForm({
          ...physicalGoldForm,
          name: '',
          symbol: '',
          description: '',
          color: '',
          clarity: '',
          cut: '',
          priceUnit: '',
          image: '',
        })

        handleCloseModal()
      } else {
        console.log(editGemstone, "error after submit")
        gemStoneAvailable()
        toast.show({
          type: 'error',
          text1: `${editGemstone.message}`,
        })
        setSelectedProduct()
        handleCloseModal()
      }
    }
  }

  const handleCloseModal = () => setGemstoneModal(false)
  const handleShowModal = (prod) => {
    console.log('prod', prod);
    setGemstoneModal(true)
    setSelectedProduct(prod)
    setImg(prod?.gemstone_image)
    setPhysicalGoldForm({
      name: prod?.gemstone_name,
      symbol: prod?.gemstone_symbol,
      description: prod?.gemstone_description,
      color: prod?.gemstone_color,
      clarity: prod?.gemstone_clarity,
      cut: prod?.gemstone_cut,
      priceUnit: prod?.gemstone_price_per_weight_unit,
    })

  }
  const handleShowModalDelete = (gem) => {
    setDeleteId(gem?.id)
    setIsOpen(true);
  };
  const permanentDeleteGemstone = async (prod) => {
    console.log('ppppppppprod', prod)

    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const delProduct = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/gemstone/permanentDeleteGemstone?gemstone_id=${deleteId}`, requestOptions)
    if (delProduct?.status === 200) {
      setIsOpen(false)
      gemStoneAvailable()
      toast.success(`gemstone succesfully got deleted`);
    } else {
      console.log('error', delProduct?.message)
    }
  }

  const inACtivateGemstone = async (prod) => {
    console.log('ppppppppprod', prod)
    const userCredentials = {
      'gold_vendor_id': vendorIdGold,
      'product_category_id': '',
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        'Content-Type': 'application/json',
      },
    };
    const delProduct = await axios.delete(`${baseURL}/gemstone/deleteGemstone`)
    console.log('delProduct delProduct', delProduct)
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSecondButtonClick = () => {
    // Logic for the second button click
    console.log('Second button clicked');
    permanentDeleteGemstone()
    // closeModal()
  };



  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Gemstone</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Gemstone:</h3>
                </Col>

              </Row>
              <Row>
                <Col md={3}>
                </Col>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>Add Gemstone</Button>
                </Col>
              </Row>

              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Gemstones:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Gemstone Name</th>
                      <th>Gemstone Image</th>
                      <th>Symbol</th>
                      <th>Color</th>
                      <th>Cut</th>
                      <th>Clarity</th>
                      <th>Description</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((prod, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{prod?.gemstone_name}</td>
                          <td>
                            <Figure>
                              <Figure.Image
                                width={100}
                                height={80}

                                src={prod?.gemstone_image}
                              />
                            </Figure>
                          </td>
                          {/* <td>{prod?.gemstone_image}</td> */}
                          <td>{prod?.gemstone_symbol}</td>
                          <td>{prod?.gemstone_color}</td>
                          <td>{prod?.gemstone_cut}</td>
                          <td>{prod?.gemstone_clarity}</td>
                          <td>{prod?.gemstone_description}</td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModal(prod)}
                              icon={faEdit} className="editIcon"
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModalDelete(prod)}
                              icon={faTrash} className="editIcon"
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={gemstoneModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Gemstone Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name='name'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  // pattern="/^[a-zA-Z]*$/"
                  required
                  value={physicalGoldForm?.name}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>symbol</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter symbol"
                  size="sm"
                  name='symbol'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.symbol}
                />
                <span className="text-danger">{physicalGoldFormErrors?.symbol}</span>
              </Form.Group>


            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Clarity</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Clarity"
                  size="sm"
                  name='clarity'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.clarity}
                />
                <span className="text-danger">{physicalGoldFormErrors?.clarity}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Coulor</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Coulor"
                  size="sm"
                  name='color'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.color}
                />
                <span className="text-danger">{physicalGoldFormErrors?.color}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>cut</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter cut"
                  size="sm"
                  name='cut'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.cut}
                />
                <span className="text-danger">{physicalGoldFormErrors?.cut}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Price/Unit</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Price/Unit"
                  size="sm"
                  name='priceUnit'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.priceUnit}
                />
                <span className="text-danger">{physicalGoldFormErrors?.priceUnit}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="Upload Image"
                  size="sm"
                  name='image'
                  onChange={(e) => setImg(e.target.files[0])}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.image}
                />
                <span className="text-danger">{physicalGoldFormErrors?.image}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Gemstone Weight unit</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Gemstone Weight unit"
                    size={"sm"}
                    className="selectsizesmall"
                    name="weightUnit"
                    onChange={handleChange}
                    value={physicalGoldForm?.weightUnit}
                  >
                    <option >Select Gemstone Weight unit</option>
                    <option name='productCategory' value='GRAMS'>{`Grams`}</option>
                    <option name='productCategory' value='CARAT'>{`Carat`}</option>
                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.weightUnit}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  // maxLength={50}
                  // type="text"
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => gemstoneImageUpload()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}

      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the gemstone ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          {/* <Button variant="warning" onClick={handleFirstButtonClick}>Inactivate</Button> */}
          <Button variant="danger" onClick={handleSecondButtonClick}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default ManageGemstone