import React from 'react'
import { useState } from "react";

import { ToastContainer, toast, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import logoAunest from './../../assets/images/LogoAunest.png'
import axios from 'axios';
import baseURL from '../authServices/Url'

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [loginForm, setLoginForm] = useState({
    phone: '',
    password: ''
  })
  const [loginFormErrors, setLoginFormErrors] = useState({
    phone: '',
    password: ''
  })
  const handleChange = (event) => {
    setLoginForm({
      ...loginForm, [event.target.name]: event.target.value
    })
    setLoginFormErrors({
      ...loginFormErrors, [event.target.name]: null
    })
  }
  const handleValidation = () => {
    const phoneRegex = /^[0-9]{10}$/;
    const { phone } = loginForm
    const newErrors = {}
    if (phoneRegex.test(phone)===false || !phone ) {
      newErrors.phone = 'please enter a valid 10-digit phone number'
    }
    return newErrors

  }

 
  const handleSubmit = () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setLoginFormErrors(handleValidationObject)
    } else {
      setLoader(true)
      let userCredentials = {
        'gold_vendor_phone_number': loginForm?.phone,
        'gold_vendor_phone_code': '91',
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userCredentials)
      };
      axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/goldVendor/auth/signin`, userCredentials, { headers: requestOptions })
  .then((response) => {
    console.log('response', response);
    if (response?.status === 200) {
      localStorage.setItem('SignInVendorCredential', JSON.stringify(response?.data?.data));
      localStorage.setItem('accessToken', response?.data?.data?.jwt_key);
      setLoader(false);
      setLoginForm({
        ...loginForm,
        phone: '',
        password: ''
      });
      setTimeout(() => {
        navigate('/LoginOtp');
      }, 1000);
    } else {
      toast.error('User credentials are invalid');
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  })
  .catch((error) => {
    console.log('error ==>', error);

    // Improved error handling
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (error.response.status === 401) {
        toast.error('This Number Not Registered OR this user not there');
      } else {
        toast.error(`Error: ${error.response.data.message || 'Something went wrong'}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('Network error: Please try again later');
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error('Error: Something went wrong');
    }

    setLoader(false);
    setLoginForm({
      ...loginForm,
      phone: '',
      password: ''
    });
  });


    }
  }



  return (
    <div>
      {" "}
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar
          transition={Zoom}
          delay={500}
          limit={1}
        />
        <div className="login">
          <div className="logo-container2">
            <div className=" logo-container">
              <img src={logoAunest} alt="" />
            </div>
          </div>

          <h2 className="login-header mb-4">Login </h2>
          
          <p>
            <input
              type="phone"
              placeholder="Phone Number"
              name='phone'
              autoComplete="off"
              value={loginForm.phone}
              onChange={handleChange}
              maxlength="10"
            />
            <span className="text-danger" >{loginFormErrors.phone}</span>
          </p>
         
          <p
            onClick={handleSubmit}
          >
            {
              loader == true ? '' :
                <input
                  type="submit"
                  value=" Continue"
                />
            }
            {
              loader == true && <div style={{ marginLeft: '170px' }} >
                <Spinner animation="border" variant="Primary" />
              </div>
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login