
import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Button, Table, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import axios from 'axios'

function VendorDashboard() {
    const [dashboardData, setDashboardData] = useState({})
    const [liveRate, setLiveRate] = useState("")
    const [userDetails] = useState(JSON.parse(localStorage.getItem("userDetails"))?.data)

    // const getDashboardData = async () => {
    //     const data = await axios.get(`http://moshimoshi.cloud:3002/product/dashboard`, {
    //         headers: { "x-access-Token": localStorage.getItem("accessToken") }
    //     }).catch((error) => {
    //         console.log('error ==>', error);
    //     })
    //     console.log("getDashboardData", data?.data?.data)
    //     localStorage.setItem('vendorData', JSON.stringify(data?.data?.data));
    //     setDashboardData(data?.data?.data)
    // }

    // const liveRateGet = async () => {
    //     try {
    //         const response = await axios({
    //             method: 'GET',
    //             url: 'http://moshimoshi.cloud:3005/cart/live-rate',
    //         });
    //         console.log("live rate data", response?.data);
    //         setLiveRate(response?.data);
    //     } catch (error) {
    //         console.log("live rate error ====> ", error);
    //     }
    // };


    // useEffect(() => {
    //     getDashboardData()
    //     liveRateGet()
    // }, [])


    return (
        <div>
            <div className="sidebar">
                <SideBar />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Dashboard</h3>
                    <div>
                        <Card className='p-2'>
                            <h3 className='headertext'>{userDetails?.name}</h3>
                            <div>
                                <h6 className='text'>Live Rate</h6>
                                <div style={{
                                    display: 'flex',
                                }}>
                                <Button>Rs 5900 /- /gm</Button>
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall w-25 m-auto"
                                    onChange={(e) => {
                                         localStorage.setItem("shopId",e.target.value)
                                         }}
                                   
                                >
                                    <option >Select Store</option>
                                    {
                                        localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).data?.shops?.map(shop => (
                                            <option value={shop._id}>{shop.name}</option>
                                        ))
                                    }
                                </Form.Select>
                                </div>
                               
                            </div>
                            <hr />
                            <Card className='p-2'>
                                <h3 className='text1'>Statistics</h3>
                                <Row>
                                    <Col md={3} className=''>
                                        <Card className='p-2 background'>
                                            <div className='centerAlign'>
                                                <h3>Jewellery Sold</h3>
                                                <div style={{ borderRadius: 50, height: 100, width: 100, border: '3px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                    <h1 style={{}}>
                                                        {dashboardData?.productsSold ? dashboardData?.productsSold : 0}
                                                    </h1>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md={3} className=' '>
                                        <Card className='p-2 background'>
                                            <h3>Schemes Sold</h3>
                                            <div style={{ borderRadius: 50, height: 100, width: 100, border: '3px solid #BE783B', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                <h1 style={{}}>
                                                    {dashboardData?.schemesSold ? dashboardData?.schemesSold : 0}
                                                </h1>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <hr />
                                <h3 className='text1'>Customers</h3>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData?.customers && dashboardData?.customers.map((user, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{user.name}</td>
                                                <td>{user.phone}</td>
                                                <td>{user.email}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Card>
                    </div>
                </div>
            </div>


            {/* <div className="row my-2">
              <div className="col-md-2">
                <h3>
                  <b>Dashboard</b>
                </h3>
              </div>
              </div> */}
            {/* <h1></h1> */}
        </div>
    )
}

export default VendorDashboard