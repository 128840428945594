import React, { useState, useEffect } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Table, Button,} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AuthServices from '../../authServices/AuthServices'

const image = require('../../../assets/imagesCustomer/image.png');

function ManageSchemes() {

  const navigate = useNavigate();
  const [schemeList, setSchemeList] = useState([])
  const [schemes] = useState(JSON.parse(localStorage.getItem('shopDetails')).schemes)
  console.log('sssssssssssssschemes', schemes);
 

  useEffect(() => {
    getUserData();
    getVendorData();
  }, []);

  const getUserData = async () => {
    try{
    const userData = await AuthServices.getDataUser('/user')
    console.log('userDataaaaaaa', userData);
    if(userData?.error === false){

    }
  }catch(e){
    console.log('error ==>', e);
  }
  }

  const getVendorData = async () => {
   const data1 = await localStorage.getItem('userDetails')
   console.log('data1', JSON.parse(data1));
   setSchemeList(JSON.parse(data1)?.schemes)
  }

  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Schemes</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Schemes:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Button variant="outline-warning" onClick={() => navigate('/ChooseScheme')}>Choose a Scheme Template</Button>
                </Col>
                <Col md={4}>
                  <Button variant="outline-warning" onClick={() => navigate('/CreateScheme')} >Create Your own Scheme</Button>
                </Col>
              </Row>
              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Schemes List:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Scheme Name</th>
                      <th>Min Amount</th>
                      <th>Tenure</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      schemeList?.map((scheme, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{scheme?.name}</td>
                          <td>RS. {scheme?.minAmt} /-</td>
                          <td>({scheme?.duration?.customerTime} + {scheme?.duration?.vendorTime})</td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => navigate('/CreateScheme', {state : {scheme}})}
                              icon={faEdit} className="editIcon"
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
     
    </div >
  )
}

export default ManageSchemes