import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Plot from "react-plotly.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import baseURL from "../../authServices/Url";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";

function MyStore() {
  const [shopModal, setShopModal] = useState(false);
  const handleCloseModal = () => setShopModal(false);
  const [action, setAction] = useState("add");
  const [logo, setLogo] = useState("");
  const [storeDetails, setStoreDetails] = useState({
    name: "",
    storeCode: "",
    nature_of_address: "",
    gst_in:"",
    address_type:"",
    street: "",
    city: "",
    state: "",
    pin: "",
    phoneCode: "",
    phone: "",
    mail: "",
    openTime: "",
    closeTime: "",
    openDays: [],
    description: "",
    authorized_person:""
  });
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedShopId, setSelectedShopId] = useState("");
  const vendorToken1 = localStorage.getItem("accessVendorToken");
  const vendorIdGold = localStorage.getItem("goldVendorId");
  const daysWeek1 = [
    { id: 1, day: "SUNDAY" },
    { id: 2, day: "MONDAY" },
    { id: 3, day: "TUESDAY" },
    { id: 4, day: "WEDNESDAY" },
    { id: 5, day: "THURSDAY" },
    { id: 6, day: "FRIDAY" },
    { id: 7, day: "SATURDAY" },
  ];

  const getStores = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
        },
      };
      const getStoreData = await axios.get(
        `https://aunest-backend.moshimoshi.cloud/api/v1/goldVendorStore/readVendorStore`,
        requestOptions
      );
      // console.log('storeData storeData', getStoreData)
      if (getStoreData?.status === 200) {
        setStoreData(getStoreData?.data?.data);
      } else {
        console.log("error", getStoreData);
      }
    } catch (err) {
      console.log("error ===>", err);
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  const handleShowModalDelete = (shop) => {
    console.log("sshoppp", shop);
    setDeleteId(shop?.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [selectedShop, setSelectedShop] = useState([]);
  const [selectedShop1, setSelectedShop1] = useState([]);

  const onSelectShop = (selectedList, selectedItem) => {
    setSelectedShop(selectedList?.map((day) => day?.day));
    setSelectedShop1(selectedList);
  };

  const onRemoveShop = (selectedList, removedItem) => {
    const updatedSelectedShopIds = selectedList?.map((item) => item?.id);
    setSelectedShop(selectedList);
    setSelectedShop1(selectedList);
  };

  const handleChange = ({ target: { name, value } }) => {
    console.log("target: { name, value } ", { ...storeDetails, [name]: value });
    setStoreDetails({ ...storeDetails, [name]: value });
  };

  const timeConverter = (time) => {
    if (!time) return ""; // add a check for undefined or empty time
    const [hours, minutes] = time.split(":");
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const time12 = `${hours12}:${minutes} ${period}`;
    return time12;
  };

  const handleOpenTimeChange = (e) => {
    const convertedTime = timeConverter(e.target.value);
    setOpenTime(convertedTime);
    setStoreDetails({ ...storeDetails, openTime: e.target.value });
  };

  const handleCloseTimeChange = (e) => {
    const convertedTime = timeConverter(e.target.value);
    setCloseTime(convertedTime);
    setStoreDetails({ ...storeDetails, closeTime: e.target.value });
  };

  const handelValidation = () => {
    const newErrors = {};
    const {
      accNumber,
      address,
      bankName,
      description,
      email,
      gst,
      ifsc,
      managerName,
      name,
      owner,
      phone,
    } = storeDetails;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    const accNumberRegex = /^\d{10}$/;
    const gstNumberRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (name === "" || nameRegex.test(nameRegex)) {
      newErrors.name = "please enter name";
    }
    if (email === "" || emailRegex.test(email)) {
      newErrors.email = "please enter a valid email address";
    }
    if (accNumber === "" || accNumberRegex.test(accNumber)) {
      newErrors.accNumber = "please enter a valid email address";
    }
    if (address === "") {
      newErrors.address = "please add Address";
    }
    if (bankName === "") {
      newErrors.address = "please enter a Bank Name";
    }
    if (description === "") {
      newErrors.description = "please add description";
    }
    if (managerName === "") {
      newErrors.managerName = "please add a Manager Name";
    }
    if (gst === "" || gstNumberRegex.test(gst)) {
      newErrors.gst = "please enter a valid gst number";
    }
    if (ifsc === "" || ifscRegex.test(ifsc)) {
      newErrors.ifsc = "please enter a valid  ifsc code ";
    }
    if (owner === "") {
      newErrors.owner = "please enter a owner Name";
    }
    if (phone === "" || phoneRegex.test(phone)) {
      newErrors.phone = "please enter a valid phone number";
    }

    return newErrors;
  };

  const addNewStore = async () => {
    try {
      const userCredentials = {
        store_code: storeDetails?.storeCode,
        store_name: storeDetails?.name,
        store_description: storeDetails?.description,
        store_address: storeDetails?.street,
        store_city: storeDetails?.city,
        store_state: storeDetails?.state,
        store_address_pincode: storeDetails?.pin,
        store_phone_code: "+91",
        store_phone_no: storeDetails?.phone,
        store_email: storeDetails?.mail,
        store_open_time: openTime,
        store_close_time: closeTime,
        store_week_days: selectedShop,
        address_type: storeDetails?.address_type,
        gst_in: storeDetails?.gst_in,
        nature_of_address: storeDetails.nature_of_address,
        authorized_person: storeDetails.authorized_person
      };
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          "Content-Type": "application/json",
        },
      };
      console.log("userCredentials", userCredentials);
      const createShop = await axios.post(
        `https://aunest-backend.moshimoshi.cloud/api/v1/goldVendorStore/createVendorStore`,
        userCredentials,
        requestOptions
      );
      console.log("createShop", createShop);
      if (createShop.status === 200) {
        toast.success(`Shop succesfully created`);
        setShopModal(false);
        setOpenTime("");
        setCloseTime("");
        setSelectedShop("");
        setSelectedShop1("");
        setStoreDetails({
          name: "",
          storeCode: "",
          street: "",
          city: "",
          state: "",
          pin: "",
          phoneCode: "",
          phone: "",
          mail: "",
          openTime: "",
          closeTime: "",
          openDays: [],
          description: "",
        });
      } else {
        setShopModal(false);
        console.log("error ===>", createShop);
      }
    } catch (err) {
      setShopModal(false);
      console.log("error ===>", err);
    }
  };

  const editStore = async () => {
    try {
      const userCredentials = {
        store_code: storeDetails?.storeCode,
        store_name: storeDetails?.name,
        store_description: storeDetails?.description,
        store_address: storeDetails?.street,
        store_city: storeDetails?.city,
        store_state: storeDetails?.state,
        store_address_pincode: storeDetails?.pin,
        store_phone_code: "+91",
        store_phone_no: storeDetails?.phone,
        store_email: storeDetails?.mail,
        store_open_time: openTime,
        store_close_time: closeTime,
        store_week_days: selectedShop,
        gold_vendor_store_id: selectedShopId,
        address_type: storeDetails?.address_type,
        gst_in: storeDetails?.gst_in,
        nature_of_address: storeDetails.nature_of_address,
        authorized_person: storeDetails.authorized_person

      };
      const requestOptions = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          "Content-Type": "application/json",
        },
      };
      const updateShop = await axios.patch(
        `https://aunest-backend.moshimoshi.cloud/api/v1/goldVendorStore/updateVendorStore`,
        userCredentials,
        requestOptions
      );
      console.log("updateShop updateShop", updateShop);
      if (updateShop?.status === 200) {
        getStores();
        setShopModal(false);
        toast.success(`Shop succesfully created`);
        setOpenTime("");
        setCloseTime("");
        setSelectedShop("");
        setSelectedShop1("");
        setStoreDetails({
          name: "",
          storeCode: "",
          street: "",
          city: "",
          state: "",
          pin: "",
          phoneCode: "",
          phone: "",
          mail: "",
          openTime: "",
          closeTime: "",
          openDays: [],
          description: "",
          address_type:"",
          gst_in:"",
          nature_of_address:"",
          authorized_person:""

        });
      } else {
        setShopModal(false);
        console.log("error ===>", updateShop);
      }
    } catch (err) {
      setShopModal(false);
      console.log("error ===>", err);
    }
  };

  const handleShowModal = (shopp = {}) => {
    // console.log('shopp', shopp);
    setSelectedShopId(shopp?.id || "");
    const formattedDays =
      shopp?.store_week_days?.map((day, i) => ({ id: i + 1, day })) || [];
    // console.log('formattedDays', formattedDays);
    setShopModal(true);
    setOpenTime(timeConverter(shopp?.store_open_time || ""));
    setCloseTime(timeConverter(shopp?.store_close_time || ""));
    setSelectedShop(shopp?.store_week_days || []);
    setSelectedShop1(formattedDays || []);
    setStoreDetails({
      name: shopp?.store_name || "",
      storeCode: shopp?.store_code || "",
      street: shopp?.store_address || "",
      city: shopp?.store_city || "",
      state: shopp?.store_state || "",
      pin: shopp?.store_address_pincode || "",
      phoneCode: shopp?.store_phone_code || "",
      phone: shopp?.store_phone_no || "",
      mail: shopp?.store_email || "",
      openTime: shopp?.store_open_time || "",
      closeTime: shopp?.store_close_time || "",
      openDays: shopp?.store_week_days || [],
      description: shopp?.store_description || "",
      address_type: shopp?.address_type || "",
      gst_in: shopp?.gst_in || "",
      nature_of_address: shopp.nature_of_address || "",
      authorized_person: shopp.authorized_person || ""
    });
  };

  const permanentDeleteStore = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${vendorToken1}`,
      },
    };
    const delStore = await axios.delete(
      `https://aunest-backend.moshimoshi.cloud/api/v1/goldVendorStore/deleteVendorStore?gold_vendor_store_id=${deleteId}`,
      requestOptions
    );
    if (delStore?.status === 200) {
      setIsOpen(false);
      toast.success(`Store succesfully got deleted`);
      getStores();
    } else {
      console.log("error", delStore?.message);
    }
  };

  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Stores</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={3}>
                  <h3 className="headertext">My Stores</h3>
                </Col>
                <Col md={3}>
                  <Button
                    variant="warning"
                    value="add"
                    onClick={handleShowModal}
                  >
                    Add new Store
                  </Button>
                </Col>
              </Row>

              <Card className="p-2">
                {/* <hr /> */}
                <h3 className="text1">Available stores</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Store Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Working Days</th>
                      <th>Open Time</th>
                      <th>Close Time</th>
                      <th>Edit</th>
                      <th>delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeData?.length &&
                      storeData?.map((shop, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{shop?.store_name}</td>
                          <td>{shop?.store_phone_no}</td>
                          <td>{shop?.store_email}</td>
                          <td>
                            {shop?.store_address} {shop?.store_city}{" "}
                            {shop?.store_state} {shop?.store_address_pincode}
                          </td>
                          <td>{shop?.store_week_days.join("\n")}</td>
                          <td>{shop?.store_open_time}</td>
                          <td>{shop?.store_close_time}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="editIcon"
                              onClick={() => {
                                // setShopToUpdate(shop._id);
                                handleShowModal(shop);
                              }}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModalDelete(shop)}
                              icon={faTrash}
                              className="editIcon"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={shopModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Add/Edit Store Details:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Store Name"
                  size="sm"
                  name="name"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.name}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Code</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Store Code"
                  size="sm"
                  name="storeCode"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.storeCode}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Nature of Address</Form.Label>
                <Form.Select
                  size="sm"
                  name="nature_of_address"
                  onChange={handleChange}
                  value={storeDetails?.nature_of_address}
                >
                  <option value="">Choose Nature of Address</option>
                  <option value="Factory / Manufacturing Unit">
                    Factory / Manufacturing Unit
                  </option>
                  <option value="Warehouse / Distribution Center">
                    Warehouse / Distribution Center
                  </option>
                  <option value="Retail Store / Outlet">
                    Retail Store / Outlet
                  </option>
                  <option value="Corporate / Head office">
                    Corporate / Head office
                  </option>
                  <option value="Regional / Branch office">
                    Regional / Branch office
                  </option>
                  <option value="Wholesaler / Trader">
                    Wholesaler / Trader
                  </option>
                  <option value="Service center">Service center</option>
                  <option value="Refinery">Refinery</option>
                  <option value="Bank">Bank</option>
                  <option value="3rd Party Vault">3rd Party Vault</option>
                  <option value="Assaying/ Hall marking Center">
                    Assaying/ Hall marking Center
                  </option>
                  <option value="Individual">Individual</option>
                </Form.Select>
                {/* Add error message if needed */}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>GST In</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Your Store Gst Number"
                  size="sm"
                  name="gst_in"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.gst_in}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address_Line 1</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Address line 1 (Max 200 characters)"
                  size="sm"
                  name="street"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.street}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address_Line 1</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Address line 2 (Max 200 characters)"
                  size="sm"
                  name="city"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.city}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="tel"
                  placeholder="Enter State"
                  size="sm"
                  name="state"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.state}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Pincode"
                  size="sm"
                  name="pin"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.pin}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter Phone Number"
                  size="sm"
                  name="phone"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.phone}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mail Id</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="email"
                  placeholder="Enter Mail Id"
                  size="sm"
                  name="mail"
                  onChange={handleChange}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.mail}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Open Time</Form.Label>
                <Form.Control
                  type="time"
                  placeholder="Enter Open Time"
                  size="sm"
                  name="openTime"
                  onChange={(e) => handleOpenTimeChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.openTime}
                />

                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Close Time</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="time"
                  placeholder="Enter Close Time"
                  size="sm"
                  name="closeTime"
                  onChange={(e) => handleCloseTimeChange(e)}
                  autoComplete="off"
                  className="mb-3"
                  value={storeDetails?.closeTime}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Week Days</Form.Label>
                <Multiselect
                  options={daysWeek1}
                  displayValue="day"
                  onSelect={onSelectShop}
                  onRemove={onRemoveShop}
                  selectedValues={selectedShop1}
                  placeholder="Select Working Days"
                  value={selectedShop}
                />
                <span className="text-danger">{}</span>
              </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address Type</Form.Label>
                <Form.Select
                  // size="sm"
                  name="address_type"
                  onChange={handleChange}
                  value={storeDetails?.address_type}
                >
                  <option value="">Choose Address Type</option>
                  <option value="Business">
                  Business
                  </option>
                  <option value="Residential">
                  Residential
                  </option>
              
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Store Authorized Person</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of the Authorized person"
                  // className="w-50"
                  name="authorized_person"
                  onChange={handleChange}
                  autoComplete="off"
                  // maxLength={50}
                  // size="sm"
                  className="mb-3"
                  // rows={3}
                  value={storeDetails?.authorized_person}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Description"
                  // className="w-50"
                  name="description"
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={50}
                  size="sm"
                  className="mb-3"
                  rows={3}
                  value={storeDetails?.description}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              selectedShopId ? editStore() : addNewStore();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}
      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the Store ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          {/* <Button variant="warning" onClick={inActivateStore}>Inactivate</Button> */}
          <Button variant="danger" onClick={() => permanentDeleteStore()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyStore;
