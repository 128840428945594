import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../authServices/Url";

function ManageOrders() {
  const [productList, setProductList] = useState([]);
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [formData, setFormData] = useState({});
  const [numberOfPackages, setNumberOfPackages] = useState(0);
  const [numberOfBoxes, setNumberOfBoxes] = useState(0);
  const [showBoxModal, setShowBoxModal] = useState(false);
  const [boxDetailsList, setBoxDetailsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const vendorToken1 = localStorage.getItem("accessVendorToken");
  const vendorIdGold = localStorage.getItem("goldVendorId");

  const getOrders = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const getOrdersData = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/productOrder?gold_vendor_id=${vendorIdGold}`,
      requestOptions
    );
    if (getOrdersData?.status === 200) {
      setProductList(getOrdersData?.data?.data);
    }
  };


  const getDeliveryMethods = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const getDeliveryMethodsData = await axios.get(
      `https://aunest-backend.moshimoshi.cloud/api/v1/goldVendorStore/readVendorStore`,
      requestOptions
    );
    if (getDeliveryMethodsData?.status === 200) {
      setDeliveryMethods(getDeliveryMethodsData?.data?.data);
    }
  };

  useEffect(() => {
    getOrders();
    getDeliveryMethods();
  }, []);

  const handleShowModalDelete = (cat) => {
    setDeleteId(cat?.id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const permanentDeleteProductCat = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const delProduct = await axios.delete(
      `${baseURL}/productCategory/deleteProductCategoryPermnt?product_category_id=${deleteId}`,
      requestOptions
    );
    if (delProduct?.status === 200) {
      setIsOpen(false);
      getOrders();
      toast.success(`Category successfully got deleted`);
    } else {
      console.log("error", delProduct?.message);
    }
  };

  const handleStatusChange = (orderId, newStatus, userId) => {
    const selectedOrder = productList.find((order) => order.id === orderId);
    if (selectedOrder) {
      
      if (selectedOrder.product_order_status === "CONFIRMED") {
        toast.error("This order is already confirmed and cannot be changed.");
        return; 
      }
  
      if (newStatus === "CONFIRMED") {
        setCurrentOrder(selectedOrder);
        setFormData({});
        setConfirmModalOpen(true);
      } else {
        updateOrderStatus(orderId, newStatus, userId);
      }
    } else {
      console.error("Order not found!");
    }
  };

  const updateOrderStatus = async (orderId, newStatus, userId) => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const updatedData = {
      product_order_status_change: newStatus,
      user_id: userId,
    };
    try {
      const updateOrderStatus = await axios.put(
        `https://aunest-backend.moshimoshi.cloud/api/v1/productOrder/updateOrderStatus?order_id=${orderId}`,
        updatedData,
        requestOptions
      );
      if (updateOrderStatus?.status === 200) {
        toast.success(`Order status updated successfully`);

        getOrders();
      }
    } catch (error) {
      console.error("Error object:", error);
      console.log("error", error?.message || "An unexpected error occurred");
      toast.error(
        `Failed to update order status: ${
          error?.message || "An unexpected error occurred"
        }`
      );
    }
  };

  const handleConfirmChange = (e) => {
    const { name, value } = e.target;
    if (name === "deliveryMethod") {
      const selectedMethod = deliveryMethods.find(
        (method) => method.id === value
      );
      const pincode = selectedMethod
        ? selectedMethod.store_address_pincode
        : "";
      const storeCode = selectedMethod ? selectedMethod.store_code : "";
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        storePincode: pincode,
        storeCode: storeCode,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleConfirmSubmit = async () => {
    if (currentOrder) {
      try {
        const availabilityResponse = await checkServiceAvailability(
          formData.storePincode
        );
        console.log("availabilityResponse", availabilityResponse);

        const toAddress = {
          consignee_name: currentOrder.toaddress[0].user_address_name,
          address_line1: currentOrder.toaddress[0].user_address,
          address_line2: currentOrder.toaddress[0].user_address_city,
          pinCode: currentOrder.toaddress[0].user_address_pincode,
          auth_receiver_name: currentOrder.toaddress[0].user_address_name,
          auth_receiver_phone:
            currentOrder.toaddress[0].user_address_phone_number,
        };

        const payload = {
          location: "domestic",
          shipmentType: "D&J",
          serviceType: "valuable",
          fromStoreCode: formData.storeCode,
          toAddress: toAddress,
          net_weight:
            currentOrder.product_order_details.product_data[0].net_weight,
          net_value: currentOrder.product_order_details.total_cart_amount,
          no_of_packages: numberOfPackages,
          boxes: boxDetailsList,
          remark: "Handle with care",
          gold_vendor_id: vendorIdGold,
          product_order_id: currentOrder.id,
        };

        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${vendorToken1}`,
          },
        };

        const shipmentResponse = await axios.post(
          "https://aunest-backend.moshimoshi.cloud/api/v1/sequel/createShipment",
          payload,
          requestOptions
        );

        console.log("shipmentResponse", shipmentResponse);

        if (shipmentResponse.status === 200) {
          await updateOrderStatus(
            currentOrder.id,
            "CONFIRMED",
            currentOrder.userId
          );
          window.location.reload();

          toast.success("Shipment created successfully");
        } else if (shipmentResponse.message.status === "false") {
          toast.error("Shipment already exists");
        } else {
          toast.error("Failed to create shipment");
        }

        setConfirmModalOpen(false);
        setFormData({});
        toast.success(availabilityResponse.data.serviceAvailblity.message);
      } catch (error) {
        console.error("Error confirming order:", error);
        setErrorMessage("Shipment already exists");
      }
    }
  };

  const checkServiceAvailability = async (pincode) => {
    try {
      const response = await axios.post(
        "https://aunest-backend.moshimoshi.cloud/api/v1/sequel/serviceAvailblity",
        { pin_code: pincode }
      );

      const { status, message } = response.data.data.serviceAvailablity;
      if (status === "false") {
        throw new Error(message);
      }
      return response.data;
    } catch (error) {
      console.error("Error checking service availability:", error);
      throw new Error(error?.message || "Failed to check service availability");
    }
  };

  const incrementPackages = () => {
    setNumberOfPackages((prev) => prev + 1);
    setFormData((prevState) => ({
      ...prevState,
      numberOfPackages: numberOfPackages + 1,
    }));
  };

  const decrementPackages = () => {
    setNumberOfPackages((prev) => (prev > 1 ? prev - 1 : 1));
    setFormData((prevState) => ({
      ...prevState,
      numberOfPackages: numberOfPackages - 1,
    }));
  };

  const incrementBoxes = () => {
    setShowBoxModal(true);
  };

  const handleBoxConfirmation = () => {
    const isDuplicateBoxNumber = boxDetailsList.some(
      (box) => box.boxnumber === formData.boxnumber
    );
    const isDuplicateLockNumber = boxDetailsList.some(
      (box) => box.locknumber === formData.locknumber
    );
  
    if (isDuplicateBoxNumber || isDuplicateLockNumber) {
      toast.error(
        `Duplicate ${isDuplicateBoxNumber ? "Box Number" : "Lock Number"} found. Please enter unique values.`
      );
    } else {
      const updatedBoxDetailsList = [...boxDetailsList, formData];
      setBoxDetailsList(updatedBoxDetailsList);
      setNumberOfBoxes((prev) => prev + 1);
      setShowBoxModal(false);
    }
  };
  

  const decrementBoxes = () => {
    if (boxDetailsList.length > 0) {
      const updatedBoxDetailsList = [...boxDetailsList];
      updatedBoxDetailsList.pop(); 
      setBoxDetailsList(updatedBoxDetailsList);
      setNumberOfBoxes((prev) => prev - 1); 
    }
  };

  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Orders</h3>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={4}>
                  <h3 className="headertext">Manage Orders:</h3>
                </Col>
              </Row>
              <Card className="p-2">
                <h3 className="text1">Available Orders:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Name</th>
                      <th>Order Image</th>
                      <th>Price</th>
                      <th>Order Payment Status</th>
                      <th>Order Delivery Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((prod, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {
                              prod?.product_order_details?.product_data[0]
                                ?.product_name
                            }
                          </td>
                          <td>
                            <Figure>
                              <Figure.Image
                                width={100}
                                height={80}
                                src={
                                  prod?.product_order_details?.product_data[0]
                                    ?.product_image[0]
                                }
                              />
                            </Figure>
                          </td>
                          <td>
                            {
                              prod?.product_order_details?.product_data[0]
                                ?.price_breakup?.total_price_by_quantity
                            }
                          </td>
                          <td>{prod?.product_order_payment_status}</td>
                          <td>
                            <Form.Control
                              as="select"
                              value={prod?.product_order_status}
                              onChange={(e) =>
                                handleStatusChange(
                                  prod?.id,
                                  e.target.value,
                                  prod?.user_id
                                )
                              }
                            >
                              <option value="PENDING">Pending</option>
                              <option value="CONFIRMED">Confirmed</option>
                            </Form.Control>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}
      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the product category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => permanentDeleteProductCat()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={confirmModalOpen} onHide={() => setConfirmModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDeliveryMethod">
              <Form.Label>
                Stores <span className="label-required">(required)</span>
              </Form.Label>

              <Form.Control
                as="select"
                name="deliveryMethod"
                value={formData.deliveryMethod || ""}
                onChange={handleConfirmChange}
              >
                <option value="">Choose a Store</option>
                {deliveryMethods.map((method, index) => (
                  <option key={index} value={method.id}>
                    {method.store_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="numberOfPackages">
              <Form.Label>
                Number of Packages{" "}
                <span className="label-required">(required)</span>
              </Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={decrementPackages}
                >
                  -
                </Button>
                <Form.Control
                  type="text"
                  readOnly
                  value={numberOfPackages}
                  className="mx-2 text-center"
                  style={{ width: "50px" }}
                />
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={incrementPackages}
                >
                  +
                </Button>
              </div>
            </Form.Group>
            <Form.Group controlId="numberOfBoxes">
              <Form.Label>
                Number of Boxes
                <span className="label-required">(optional)</span>
              </Form.Label>
              <div className="d-flex align-items-center">
                <Button variant="secondary" size="sm" onClick={decrementBoxes}>
                  -
                </Button>
                <Form.Control
                  type="text"
                  readOnly
                  value={numberOfBoxes}
                  className="mx-2 text-center"
                  style={{ width: "50px" }}
                />
                <Button variant="secondary" size="sm" onClick={incrementBoxes}>
                  +
                </Button>
              </div>
            </Form.Group>

            {/* Render box details list */}
            {boxDetailsList.map((box, index) => (
              <div key={index} className="border p-3 mb-3">
                <h5>Box {index + 1} Details:</h5>
                <div className="d-flex justify-content-between">
                  <p>
                    <strong>Box Number:</strong> {box.boxnumber}
                  </p>
                  <p>
                    <strong>Lock Number:</strong> {box.locknumber}
                  </p>
                </div>
                {/* Add other box details fields as needed */}
              </div>
            ))}
            {errorMessage && (
              <div className="alert alert-danger mt-3">{errorMessage}</div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmSubmit}
            disabled={!formData.deliveryMethod || !numberOfPackages}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Box Details Modal */}
      <Modal show={showBoxModal} onHide={() => setShowBoxModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Box Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="boxnumber">
              <Form.Label>Box Number(Unique Number)</Form.Label>
              <Form.Control
                type="text"
                name="boxnumber"
                value={formData.boxnumber || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
            <Form.Group controlId="locknumber">
              <Form.Label>Lock Number(on the Box)</Form.Label>
              <Form.Control
                type="text"
                name="locknumber"
                value={formData.locknumber || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
            <Form.Group controlId="length">
              <Form.Label>Length(CMS)</Form.Label>
              <Form.Control
                type="text"
                name="length"
                value={formData.length || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
            <Form.Group controlId="breadth">
              <Form.Label>Breadth(CMS)</Form.Label>
              <Form.Control
                type="text"
                name="breadth"
                value={formData.breadth || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
            <Form.Group controlId="height">
              <Form.Label>Height(CMS)</Form.Label>
              <Form.Control
                type="text"
                name="height"
                value={formData.height || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
            <Form.Group controlId="gross_weight">
              <Form.Label>Gross Weight(CMS)</Form.Label>
              <Form.Control
                type="text"
                name="gross_weight"
                value={formData.gross_weight || ""}
                onChange={handleConfirmChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBoxModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleBoxConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ManageOrders;
