import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from 'axios'


const image = require('../../../assets/imagesCustomer/image.png');

function JewellerySold() {
    const [jewelleryModal, setJewelleryModal] = useState(false)
    const [coinModal, setCoinModal] = useState(false)
    const [selection, setSelection] = useState('1')
    const [stats, setStats] = useState('');
    const [shopId] = useState(localStorage.getItem('shopId'));

    const navigate = useNavigate();

    const onGoldSelect = (e) => {
        setSelection(e.target.value)
    }

    useEffect(() => {
        const getStats = async () => {
            const data = await axios.get(`http://moshimoshi.cloud:3002/shop/stats/${shopId}`, {
                headers: { "x-access-Token": localStorage.getItem("accessToken") }
            }).catch((error) => {
                console.log('error ==>', error);
            })
            console.log("getShopStats ===>", data.data.data)
            setStats(data.data.data)
        }
        getStats();
    }, [shopId])

    return (
        <div>
            <div className="sidebar">
                <SideBar />
            </div>
            <div className="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Jewellery Sold:</h3>
                    <div>
                        <Card className='p-2'>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {
                                    stats.soldData.product && stats.soldData.product.map((p, i) => (
                                        <div onClick={() => navigate('/Jewellery')} style={{ width: 200, height: 250, border: '1px solid', margin: 10 }} key={i}>
                                        </div>
                                    ))
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default JewellerySold