import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { toast } from "react-toastify";
import AuthServices from '../../authServices/AuthServices'
import baseURL from '../../authServices/Url';

function ManageProductCategory() {
  const [metalModal, setMetalModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [img, setImg] = useState();
  const [brand, setBrand] = useState('');

  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    name: '',
    image: '',
    // description: '',

  })
  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    image: '',
    description: '',

  })
  const vendorToken1 = localStorage.getItem('accessVendorToken');
  const vendorIdGold = localStorage.getItem('goldVendorId');
  // console.log('vendorToken1 vendorToken1', vendorToken1);
  // console.log('vendorIdGold', vendorIdGold);

  const getProductCategories = async () => {
    const getCategories = await axios.get(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/readProductCategory?gold_vendor_id=${vendorIdGold}`)
    // console.log('gggggggggggggggetCategories', getCategories);
    if (getCategories?.status === 200) {
      setProductList(getCategories?.data?.data)
    }
  }
  const brandsAvailable = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const getbrand = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/vendor/readBrandEndorsement?gold_vendor_id=${vendorIdGold}`, '', requestOptions)
    // console.log('gggggggggggggggetCategories', getbrand);
    if (getbrand?.status === 200) {
      setBrandList(getbrand?.data?.data)
    }
  }
  useEffect(() => {
    getProductCategories()
    brandsAvailable()
  }, [])
  const handleShowModalDelete = (cat) => {
    setDeleteId(cat?.id)
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCloseModal = () => setMetalModal(false)
  const handleShowModal = (prod) => {
    console.log('prod', prod);
    setMetalModal(true)
    setSelectedProduct(prod)
    setPhysicalGoldForm({
      name: prod?.category_name,
      image: prod?.category_image,
      // description: prod?.category_description,

    })
    setImg(prod?.category_image)
    setBrand(prod?.brand_id)

  }


  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.image]: e.target.value,
      [e.target.description]: e.target.description,

    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.image]: null,

    });
  };

  const handleValidation = () => {
    const { name, } = physicalGoldForm
    const newErrors = {}

    if (!name) {
      newErrors.name = 'please enter name'
    }
    if (!img) {
      newErrors.image = 'please upload image'
    }
    // if (!description) {
    //   newErrors.description = 'please enter description'
    // }

    return newErrors
  }

  const categoryImageUpload = async () => {

    try {

      var formdata = new FormData();
      formdata.append("image", img);
      console.log('image userCredentials', img)
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
        },

      };

      const imgUpload = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/mediaMingle/upload`, formdata, requestOptions)
      console.log('imgUpload imgUpload imgUpload', imgUpload)
      if (imgUpload?.status === 200) {
        console.log('selectedProduct?.id', selectedProduct?.id, imgUpload?.data?.data?.url)
        selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
        console.log('handleUpdate')
      }
    } catch (err) {
      console.log('err0r ==>', err)
    }
  }

  const handleSubmit = async (url) => {
    console.log('handleSubmit')
    try {
      const handleValidationObject = handleValidation()

      if (Object.keys(handleValidationObject).length > 0) {
        setPhysicalGoldFormErrors(handleValidationObject)
      } else {
        console.log('handleSubmit')
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          'category_name': physicalGoldForm?.name,
          'category_image': url,
          // 'category_description': physicalGoldForm?.description,
          "brand_id": brand,
        })


        const createProductCategory = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/createProductCategory`, userCredentials, requestOptions)

        if (createProductCategory?.status === 200) {
          getProductCategories()
          setPhysicalGoldForm({
            ...physicalGoldForm,
            name: '',
            image: '',
            description: '',

          })
          setBrand('')
          handleCloseModal()
          toast.success(`Product Category succesfully created`);

        } else {
          console.log(createProductCategory, "error after submit")
          toast.show({
            type: 'error',
            text1: `${createProductCategory.message}`,
          })
          handleCloseModal()
        }
      }
    } catch (e) {
      console.log('error ==>', e)
      if (e?.response?.status === 409) {
        handleCloseModal()
        toast.error(`${e?.response?.data?.message}`);
      }

    }
  }

  const handleSubmitEdit = async (url) => {
    try {
      const handleValidationObject = handleValidation()
      if (Object.keys(handleValidationObject).length > 0) {
        setPhysicalGoldFormErrors(handleValidationObject)
      } else {
        console.log('handleSubmitEdit')
        const requestOptions = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          'category_name': physicalGoldForm?.name,
          'category_image': url ? url : physicalGoldForm?.image,
          // 'category_description': physicalGoldForm?.description,
          "gold_vendor_id": vendorIdGold,
          "product_category_id": selectedProduct?.id,
          "brand_id": brand,
        })

        const EditProductCategory = await axios.put(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/updateProductCategory`, userCredentials, requestOptions)
        console.log('EditProductCategory EditProductCategory', EditProductCategory);
        if (EditProductCategory?.status === 200) {
          toast.success(`Product Category edited succesfully `);
          getProductCategories()
          setPhysicalGoldForm({
            ...physicalGoldForm,
            name: '',
            image: '',
            description: '',

          })
          setBrand('')
          handleCloseModal()
        } else {
          console.log(EditProductCategory, "error after submit")
          getProductCategories()
          toast.show({
            type: 'error',
            text1: `${EditProductCategory.message}`,
          })
          setSelectedProduct()
          handleCloseModal()
        }


      }
    } catch (e) {
      console.log('error ==>', e)
      if (e?.response?.status === 409) {
        handleCloseModal()
        toast.error(`${e?.response?.data?.message}`);
      }

    }
  }

  const permanentDeleteProductCat = async () => {

    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const delProduct = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/deleteProductCategoryPermnt?product_category_id=${deleteId}`, requestOptions)
    if (delProduct?.status === 200) {
      setIsOpen(false)
      getProductCategories()
      toast.success(`Category succesfully got deleted`);
    } else {
      console.log('error', delProduct?.message)
    }
  }


  const inACtivateProductCat = async (prod) => {
    console.log('ppppppppprod', prod)
    const userCredentials = {
      'gold_vendor_id': vendorIdGold,
      'product_category_id': '',
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        'Content-Type': 'application/json',
      },
    };
    const delProduct = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/deleteProductCategoryPermnt`, userCredentials, requestOptions)
    console.log('delProduct delProduct', delProduct)
  }




  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Product Category</h3>
          <div>
            <Card className='p-2 '>
              <Row>
                <Col md={9}>
                  <h3 className='headertext'>Manage Product Category:</h3>
                </Col>

              </Row>
              <Row>
                <Col md={6}>
                </Col>
                <Col md={3} className="d-flex justify-content-end align-items-start">
                  <Button  variant="warning" onClick={handleShowModal}>Add Product Category </Button>
                </Col>
              </Row>

              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Product Category:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Product Category Name</th>
                      <th>Product Category Image</th>
                      <th>Brand Name</th>
                      {/* <th>Description</th> */}
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((prod, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{prod?.category_name}</td>
                          <td>
                            <Figure>
                              <Figure.Image
                                width={100}
                                height={80}

                                src={prod?.category_image}
                              />
                            </Figure>
                          </td>
                          <td>{prod?.brand_endorsment?.brand_name ? prod?.brand_endorsment?.brand_name : '-'}</td>
                          {/* <td>{prod?.category_description}</td> */}
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModal(prod)}
                              icon={faEdit} className="editIcon"
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModalDelete(prod)}
                              icon={faTrash} className="editIcon"
                            />
                          </td>

                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={metalModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Product Category Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Category Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Product Category Name"
                  size="sm"
                  name='name'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  // pattern="/^[a-zA-Z]*$/"
                  required
                  value={physicalGoldForm?.name}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Figure>
              <Figure.Image
                width={100}
                height={100}
                alt="171x180"
                // src={gemstoneData1?.gemstone_image}
                src={img}
              />
            </Figure>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Product Category Image</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="file"
                  placeholder="upload Product Category Image"
                  size="sm"
                  name='image'
                  onChange={(e) => setImg(e.target.files[0])}
                  autoComplete='off'
                  className='mb-3'
                />
                <span className="text-danger">{physicalGoldFormErrors?.image}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Brand</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Brand"
                    size={"sm"}
                    className="selectsizesmall"
                    name="brand"
                    onChange={(e) => setBrand(e.target.value)}
                    value={brand}
                  >

                    <option >Select Brand</option>
                    {brandList?.map((item, i) => {
                      return (
                        <option key={i} name='brand' value={item?.id}>{item?.brand_name}</option>
                      )
                    })}
                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                 
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => categoryImageUpload()}>
            {/* {  selectedProduct?.id ? handleSubmitEdit() : handleSubmit() } */}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}

      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the product category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          {/* <Button variant="secondary" onClick={inACtivateProductCat}>Inactivate</Button> */}
          <Button variant="danger" onClick={() => permanentDeleteProductCat()}>Delete</Button>
        </Modal.Footer>
      </Modal >

    </div >
  )
}

export default ManageProductCategory