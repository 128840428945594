import React, { useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'

const image = require('../../../assets/imagesCustomer/image.png');

function AddBanner() {
  const [jewelleryModal, setJewelleryModal] = useState(false)
  const [coinModal, setCoinModal] = useState(false)
  const [selection, setSelection] = useState('1')

  const handleCloseModal = () => setJewelleryModal(false)
  const handleShowModal = () => setJewelleryModal(true)

  const handleCloseModal1 = () => setCoinModal(false)
  const handleShowModal1 = () => setCoinModal(true)

  const onGoldSelect = (e) => {
    setSelection(e.target.value)
  }

  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");

 

  // const handleValidation = () => {
//     // console.log(temp.gst.length)
//     const newErrors = {}
//     if (!imagesData) {
//         Toast.show({
//             type: 'error',
//             text1: `Please upload an image`,
//         })
//     }
//     if (heading == '') {
//         newErrors.heading = 'Please enter heading'
//     }
//     if (description == '') {
//         newErrors.description = 'Please enter description'
//     }

//     return newErrors
// }

  const uploadBanner = async () => {
//     {
//         const handleValidationObject = handleValidation()
//         console.log(handleValidationObject)
//         if (Object.keys(handleValidationObject).length > 0) {
//             setInputErrors(handleValidationObject)
//         }

//             else {
//                 try {
//                     const formData = new FormData();
//                     let token = await getToken()
//                     const store = await getStore()
//                     const headers = {
//                         'x-access-token': token,
//                         'content-type': 'multipart/form-data',
//                     }
//                     formData.append('heading', heading)
//                     formData.append('bannerDescription', description)
//                     formData.append('banner_img', { uri: imagesData, name: 'photo.png', filename: 'imageName.png', type: 'image/png' })
//                     console.log("ASSETS", store, formData)
//                     const profileData = await profileApiServices.patchProfile('/shop/' + store, formData, { headers })
//                         .then(async (result) => {
//                             console.log(result.data.data.banner_img, "RESP BANNER")
//                             await getProfile()
//                             return result
//                         }
//                         )
//                 }
//                 catch (e) {
//                     console.log(e);
//                 }
//             }
//     }
}

 const deleteBanner = async () => {
//     {
//         try {
//             const formData = new FormData();
//             let token = await getToken()
//             const store = await getStore()
//             const headers = {
//                 'x-access-token': token,
//                 'content-type': 'multipart/form-data',
//             }
//             formData.append('deleteBannerId', id)
//             console.log("ASSETS", store, formData)
//             const profileData = await profileApiServices.patchProfile('/shop/' + store, formData, { headers })
//                 .then(async (result) => {
//                     console.log(result.data.data.banner_img, "RESP BANNER")
//                     await getProfile()
//                     return result
//                 }
//                 )
//         }
//         catch (e) {
//             console.log(e);
//         }
//     }
}


  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Banner :</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Banner:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Button variant="warning" onClick={handleShowModal}>Upload new Banner:</Button>
                </Col>
              </Row>
              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Banner:</h3>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div onClick={handleShowModal1} style={{ width: 200, height: 250, border: '1px solid', margin: 10 }}>

                  </div>
                  <div style={{ width: 200, height: 250, border: '1px solid', margin: 10 }}>

                  </div>
                  <div style={{ width: 200, height: 250, border: '1px solid', margin: 10 }}>

                  </div>
                  <div style={{ width: 200, height: 250, border: '1px solid', margin: 10 }}>

                  </div>
                  <div style={{ width: 200, height: 250, border: '1px solid', margin: 10 }}>

                  </div>
                </div>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Jewellery Name</th>
                      <th>Jewellery Image</th>
                      <th>Price</th>
                      <th>weight</th>
                      <th>category</th>
                      <th>metal</th>
                      <th>purity</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Ring</td>
                      <td>
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={80}
                            src={image}
                          />
                        </Figure>
                      </td>
                      <td>RS. 15,000 /-</td>
                      <td>3 gms</td>
                      <td>ring</td>
                      <td>gold</td>
                      <td>24</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => handleShowModal()}
                          icon={faEdit} className="editIcon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Ring</td>
                      <td>
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={80}
                            src={image}
                          />
                        </Figure>
                      </td>
                      <td>RS. 15,000 /-</td>
                      <td>3 gms</td>
                      <td>ring</td>
                      <td>gold</td>
                      <td>24</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => handleShowModal()}
                          icon={faEdit} className="editIcon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Ring</td>
                      <td>
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={80}
                            src={image}
                          />
                        </Figure>
                      </td>
                      <td>RS. 15,000 /-</td>
                      <td>3 gms</td>
                      <td>ring</td>
                      <td>gold</td>
                      <td>24</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => handleShowModal()}
                          icon={faEdit} className="editIcon"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table> */}
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Upload Banner Image:</h4>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="w-50">
                <Form.Label>Add Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Banner Title"
                  className="mb-3"
                  name="title"
                  onChange={(e) => setFile(e.target.files[0])}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="w-50">
                <Form.Label>Add Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Banner Description"
                  as="textarea"
                  className="mb-3"
                  name="title"
                  onChange={(e) => setFile(e.target.files[0])}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="w-50">

                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Brochure PDF"
                  className="mb-3"
                  name="file"
                  onChange={(e) => setTitle(e.target.value)}
                  autoComplete="off"
                />

              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={uploadBanner}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={coinModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Banner :</h4>
        <Modal.Body>
          <Row>
            <Col>
              <p className='headertext1'>Heading:</p>
              <p className='headertext'>Heading</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='headertext1'>Description:</p>
              <p className='headertext'>Description</p>
            </Col>
          </Row>
          <Row>
            <Figure>
              <Figure.Image
                width={100}
                height={80}
                src={image}
              />
            </Figure>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default AddBanner