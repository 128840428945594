import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { toast } from "react-toastify";
import AuthServices from '../../authServices/AuthServices';


const image = require('../../../assets/imagesCustomer/image.png');

function AddAdvertisement() {
  const [jewelleryModal, setJewelleryModal] = useState(false)
  const [coinModal, setCoinModal] = useState(false)
  const [inputErrors, setInputErrors] = useState({ name: '', description: '', image: '', store: '' })
  const [input, setInput] = useState({ name: '', description: '', store: '' })
  const [imagesData, setImagesData] = useState('')
  const [selectedAd, setSelectedAd] = useState('')
  const [selectedShop, setSelectedShop] = useState('')
  const [advert, setAdvert] = useState([])

  const handleCloseModal = () => setJewelleryModal(false)
  const handleShowModal = () => setJewelleryModal(true)

  const handleCloseModal1 = () => setCoinModal(false)
  const handleShowModal1 = (advert) => {
    setCoinModal(true)
    setSelectedAd(advert)
  }

  const handleStoreChange = (e) => {
    console.log('shop eeeee ==>', e.target.value);
    setInput({ ...input, store: e.target.value })
  }

  const handleStoreChange1 = (e) => {
    console.log('shop1 eeeee ==>', e.target.value);
    // setInput({ ...input, store: e.target.value })
    setSelectedShop(e.target.value)
  }

  const handleChange = (e) => {
    setInput({
      ...input,
      [e?.target?.name]: e?.target?.value,
      [e.target.description]: e.target.value,
      [e.target.store]: e.target.value,
    });

    setInputErrors({
      ...inputErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.image]: null,
      [e.target.store]: null,
    });
  };
  const handleValidation = () => {
    const { name, description, store } = input
    const newErrors = {}
    if (!imagesData) {
      newErrors.image = 'Please upload an image';
    }
    if (name == '') {
      newErrors.name = 'Please enter heading'
    }
    if (description == '') {
      newErrors.description = 'Please enter description'
    }
    if (store == '') {
      newErrors.store = 'Please select store'
    }

    return newErrors
  }

  const uploadAdvertisement = async () => {
    try {
      const handleValidationObject = handleValidation()
      console.log(handleValidationObject)
      if (Object.keys(handleValidationObject).length > 0) {
        setInputErrors(handleValidationObject)
      }
      else {
        const formData = new FormData();
        formData.append('name', input?.name)
        formData.append('description', input?.description)
        formData.append('image', imagesData)
        const profileData = await AuthServices.postDataProduct(`/shop/advert/${input?.store}`, formData)
        console.log('profileData', profileData);
        if (profileData?.error === false) {
          setInput({
            ...input,
            name: '',
            description: '',
            shop: '',
          })
          setImagesData()
          getShopDetail()
          handleCloseModal()
        }
      }
    } catch (e) {
      console.log('error ===> ', e);
    }
  }

  const deleteBanner = async (id) => {
    try {
     console.log('selectedShop?.owner', selectedShop);
      const profileData = await AuthServices?.deleteDataProduct(`/shop/advert/${selectedShop}?advertId=${selectedAd?._id}`)
      if (profileData?.error === false) {
        getShopDetail()
        handleCloseModal1()
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getShopDetail();
  }, [selectedShop])

  const getShopDetail = async () => {
    const shopId = JSON.parse(localStorage.getItem('userDetails')).data?.shops[0]?._id
    const shopDetail = await AuthServices?.getDataProduct(`/shop/list/${selectedShop}`)
    if (shopDetail?.error === false) {
      console.log('shopDetail', shopDetail);
      setAdvert(shopDetail?.data?.advertisment)
    }
  }

  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Add Advertisement</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Advertisement:</h3>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Button variant="warning" onClick={handleShowModal}>Upload new Advertisement:</Button>
                </Col>
              </Row>
              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Advertisements:</h3>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Store:</Form.Label>
                      <div className="leftedge d-flex justify-content-space">
                        <Form.Select
                          aria-label="Default select example"
                          size={"sm"}
                          className="selectsizesmall"
                          onChange={handleStoreChange1}
                        >
                          <option >Select Store</option>
                          {
                            localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).data?.shops?.map(shop => (
                              <option name="shops" value={shop?._id}>{shop?.name}</option>
                            ))
                          }
                        </Form.Select>
                      </div>
                      <span className="text-danger">{inputErrors?.store}</span>
                    </Form.Group>
                  </Col>
                </Row>
                {selectedShop ?
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    { advert?.length !== 0 ?
                    ( advert?.map((advert, i) => {
                      return (
                        <div key={i} onClick={() => handleShowModal1(advert)} style={{ width: 200, height: 250, margin: 10 }}>
                          <Figure>
                            <Figure.Image
                              width={200}
                              height={250}
                              alt="171x180"
                              src={advert?.image}
                            // onChange={}
                            />
                          </Figure>
                        </div>
                      )
                    })
                    ):(
                      <p>No advertisements for the selected Store</p>
                    )}
                  </div> :
                  <div>
                    <p>Please select Store.</p>
                  </div>}

              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Upload Advertisements Image:</h4>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Store:</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="Default select example"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={handleStoreChange}
                  >
                    <option >Select Store</option>
                    {
                      localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).data?.shops?.map(shop => (
                        <option name="shops" value={shop?._id}>{shop?.name}</option>
                      ))
                    }
                  </Form.Select>
                </div>
                <span className="text-danger">{inputErrors?.store}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="w-50">
                <Form.Label>Add Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Advertisements Title"
                  className="mb-3"
                  name="name"
                  onChange={handleChange}
                  autoComplete="off"
                  value={input?.name}
                />
              </Form.Group>
              <span className="text-danger">{inputErrors?.name}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="w-50">
                <Form.Label>Add Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Advertisements Description"
                  as="textarea"
                  className="mb-3"
                  name="description"
                  onChange={handleChange}
                  autoComplete="off"
                  value={input?.description}
                />
              </Form.Group>
              <span className="text-danger">{inputErrors?.description}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="w-50">

                <Form.Label>Advertisements Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Advertisements Image"
                  className="mb-3"
                  name="file"
                  onChange={(e) => setImagesData(e.target.files[0])}
                  autoComplete="off"
                />

              </Form.Group>
              <span className="text-danger">{inputErrors?.image}</span>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={uploadAdvertisement}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={coinModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Advertisement :</h4>
        <Modal.Body>
          <Row>
            <Col>
              <p className='headertext1'>Heading:</p>
              <p className='headertext'>{selectedAd?.name}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='headertext1'>Description:</p>
              <p className='headertext'>{selectedAd?.description}</p>
            </Col>
          </Row>
          <Row>
            <Figure>
              <Figure.Image
                width={500}
                height={600}
                src={selectedAd?.image}
              />
            </Figure>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteBanner} variant="warning">
            Delete
          </Button>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default AddAdvertisement