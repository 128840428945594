import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form, ModalBody, ModalFooter } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { toast } from "react-toastify";
import AuthServices from '../../authServices/AuthServices'
import baseURL from '../../authServices/Url';

function ManageProductOffer() {
  const [metalModal, setMetalModal] = useState(false)
  const [productList, setProductList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [isOpen, setIsOpen] = useState(false);
const [deleteOffer,setDeleteOffer]=useState({})
  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    name: '',
    type: '',
    description: '',
    startDate: '',
    endDate: '',
    termsConditions: [],
  })
  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    type: '',
    description: '',
    startDate: '',
    endDate: '',
    termsConditions: '',

  })
  const vendorToken1 = localStorage.getItem('accessVendorToken');
  const vendorIdGold = localStorage.getItem('goldVendorId');
  // console.log('vendorToken1 vendorToken1', vendorToken1);
  // console.log('vendorIdGold', vendorIdGold);

  const getProductOffer = async () => {
    const getOffer = await axios.get(`https://aunest-backend.moshimoshi.cloud/api/v1/productOffer?gold_vendor_id=${vendorIdGold}`)
    console.log('gggggggggggggggetOffer', getOffer);
    if (getOffer?.status === 200) {
      setProductList(getOffer?.data?.data)
    }
  }
  useEffect(() => {
    getProductOffer()
  }, [])

  const handleCloseModal = () => setMetalModal(false)
  const handleShowModal = (offer) => {
    // console.log('offer', offer);
    setMetalModal(true)
    setSelectedProduct(offer)
    setPhysicalGoldForm({
      name: offer?.offer_name,
      type: offer?.offer_type,
      description: offer?.offer_description,
      startDate: offer?.offer_start_date,
      endDate: offer?.offer_end_date,
      termsConditions: offer?.offer_terms_and_conditions,
      
    })

  }

  const handleChangeTerms = (e) => {
    console.log('handleChangeTerms handleChangeTerms', e.target.value)
    const { value } = e.target;
    setPhysicalGoldForm({
      ...physicalGoldForm, termsConditions: value.split('\n')
    });
  }

  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.type]: e.target.value,
      [e.target.description]: e.target.value,
      [e.target.startDate]: e.target.value,
      [e.target.endDate]: e.target.value,
      [e.target.termsConditions]: e.target.value.split('\n'),
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.type]: null,
      [e.target.startDate]: null,
      [e.target.endDate]: null,
      [e.target.termsConditions]: null,
    });
  };

  const handleValidation = () => {
    const { name, type, description, endDate, startDate, termsConditions } = physicalGoldForm
    const newErrors = {}

    if (!name) {
      newErrors.name = 'please enter name'
    }
    if (!type) {
      newErrors.type = 'please upload type'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    if (!startDate) {
      newErrors.startDate = 'please select start Date'
    }
    if (!endDate) {
      newErrors.endDate = 'please select End Date'
    }
    if (!termsConditions) {
      newErrors.termsConditions = 'please enter Terms & Conditions'
    }

    return newErrors
  }

  const handleSubmit = async () => {
    try {
      const handleValidationObject = handleValidation()

      if (Object.keys(handleValidationObject).length > 0) {
        setPhysicalGoldFormErrors(handleValidationObject)
      } else {
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          "offer_name": physicalGoldForm?.name,
          "offer_description": physicalGoldForm?.description,
          "offer_type": physicalGoldForm?.type,
          "offer_start_date": physicalGoldForm?.startDate,
          "offer_end_date": physicalGoldForm?.endDate,
          "offer_terms_and_conditions": physicalGoldForm?.termsConditions,
        })
        const createProductOffer = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/productOffer`, userCredentials, requestOptions)
        if (createProductOffer?.status === 200) {
          setPhysicalGoldForm({
            ...physicalGoldForm,
            name: '',
            type: '',
            description: '',
            startDate: '',
            endDate: '',
            termsConditions: '',

          })
          handleCloseModal()
          getProductOffer()
          toast.success(`offer succesfully created`);

        } else {
          console.log(createProductOffer, "error after submit")
          toast.show({
            type: 'error',
            text1: `${createProductOffer.message}`,
          })
          handleCloseModal()
        }
      }
    } catch (e) {
      console.log('error ==>', e)
    }
  }

  const handleSubmitEdit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = JSON.stringify({
        "offer_name": physicalGoldForm?.name,
        "offer_description": physicalGoldForm?.description,
        "offer_type": physicalGoldForm?.type,
        "offer_start_date": physicalGoldForm?.startDate,
        "offer_end_date": physicalGoldForm?.endDate,
        "offer_terms_and_conditions": physicalGoldForm?.termsConditions,
        "offer_id":selectedProduct?.id,
      })
      const editOffer = await axios.patch(`https://aunest-backend.moshimoshi.cloud/api/v1/productOffer`, userCredentials, requestOptions)
      console.log('editOffer editOffer', editOffer);
      if (editOffer?.status === 200) {
        toast.success(`offer edited succesfully `);
        getProductOffer()
        setPhysicalGoldForm({
          ...physicalGoldForm,
          name: '',
          type: '',
          description: '',
          startDate: '',
          endDate: '',
          termsConditions: '',

        })
        handleCloseModal()

      } else {
        console.log(editOffer, "error after submit")
        getProductOffer()
        toast.show({
          type: 'error',
          text1: `${editOffer.message}`,
        })
        setSelectedProduct()
        handleCloseModal()
      }


    }
  }

  const inACtivateProductCat = async (prod) => {
    console.log('ppppppppprod', prod)
    const userCredentials = {
      'gold_vendor_id': vendorIdGold,
      'product_category_id': '',
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        'Content-Type': 'application/json',
      },
    };
    const delOffer = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/productCategory/deleteProductCategoryPermnt`, userCredentials, requestOptions)
    console.log('delOffer delOffer', delOffer)
  }

  const handleShowModalDelete = (offer) => {
    setIsOpen(true);
    setDeleteOffer(offer?.id)
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const permanentDeleteOffer = async (offer) => {
    console.log('ooooooooooffer', offer)
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        'Content-Type': 'application/json',
      },
    };
    const delOffer = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/productOffer?offer_id=${deleteOffer}`, requestOptions)
    // console.log('delOffer delOffer', delOffer)
    if (delOffer?.status === 200) {
      setIsOpen(false)
      getProductOffer()
      toast.success(`Offer succesfully got deleted`);
    } else {
      console.log('error', delOffer?.message)
    }
  }
  const termsConditionsString = Array.isArray(physicalGoldForm?.termsConditions)
  ? physicalGoldForm?.termsConditions.join('\n')
  : '';
  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Product Offers</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Product Offers:</h3>
                </Col>

              </Row>
              <Row>
                <Col md={3}>
                </Col>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>Add Product Offers</Button>
                </Col>
              </Row>

              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Product Offers:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> offer Name</th>
                      <th> offer type</th>
                      <th> Start Date</th>
                      <th> End Date</th>
                      <th> Terms & Conditions</th>
                      <th>Description</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((offer, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{offer?.offer_name}</td>
                          <td>{offer?.offer_type}</td>
                          <td>{offer?.offer_start_date}</td>
                          <td>{offer?.offer_end_date}</td>
                          <td>
                            {offer?.offer_terms_and_conditions.map((term, index) => (
                              <div key={index}>
                                {index + 1}: {term}
                              </div>
                            ))}
                          </td>
                          <td>{offer?.offer_description}</td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModal(offer)}
                              icon={faEdit} className="editIcon"
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModalDelete(offer)}
                              icon={faTrash} className="editIcon"
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={metalModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Product Offers Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Offer Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Offer Name"
                  size="sm"
                  name='name'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  // pattern="/^[a-zA-Z]*$/"
                  required
                  value={physicalGoldForm?.name}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Offer type</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Offer type"
                  size="sm"
                  name='type'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.type}
                />
                <span className="text-danger">{physicalGoldFormErrors?.type}</span>
              </Form.Group>


            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={physicalGoldForm?.startDate}
                  onChange={handleChange}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={physicalGoldForm?.endDate}
                  onChange={handleChange}
                />
                <span className="text-danger">{physicalGoldFormErrors?.type}</span>
              </Form.Group>


            </Col>
          </Row>
          <Row>
            <Col lg >
              <Form.Group className="mb-3">
                <Form.Label>Terms and conditions</Form.Label>
                <Form.Control
                 
                  as="textarea" rows={3}
                  placeholder="Enter every new Terms and conditions in a new line"
                  size="md"
                  name='termsConditions'
                  onChange={handleChangeTerms}
                  autoComplete='off'
                  className='mb-3'
                  value={termsConditionsString}
                  style={{ whiteSpace: 'pre-line' }}
  // value={physicalGoldForm?.termsConditions.join('\n')}
                />
                <span className="text-danger">{physicalGoldFormErrors?.termsConditions}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  // maxLength={50}
                  // type="text"
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => { selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}

      >
        <ModalBody>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the product offer ?</p>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          {/* <Button variant="secondary" onClick={inACtivateProductCat}>Inactivate</Button> */}
          <Button variant="danger" onClick={(e)=>permanentDeleteOffer(e)}>Delete</Button>
        </ModalFooter>
      </Modal>
    </div >
  )
}

export default ManageProductOffer