import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import VendorDashboard from "./pages/VendorPanel/vendorDashboard/VendorDashboard";
import VendorProfile from "./pages/VendorPanel/vendorProfile/VendorProfile";
import MyStore from "./pages/VendorPanel/myStore/MyStore";
import ManageAdmin from "./pages/VendorPanel/manageAdmin/ManageAdmin";
import ManagePhysicalGold from "./pages/VendorPanel/managePhysicalGold/ManagePhysicalGold";
import ManageSchemes from "./pages/VendorPanel/manageSchemes/ManageSchemes";
import Stats from "./pages/VendorPanel/storeStats/Stats";
import AddBrochure from "./pages/VendorPanel/addBrochure/AddBrochure";
import CreateScheme from "./pages/VendorPanel/manageSchemes/CreateScheme";
import JewellerySold from "./pages/VendorPanel/storeStats/JewellerySold";
import JewelleryDetail from "./pages/VendorPanel/storeStats/JewelleryDetail";
import SchemeDetail from "./pages/VendorPanel/storeStats/SchemeDetail";
import SchemesSold from "./pages/VendorPanel/storeStats/SchemesSold";
import BrochureRequest from "./pages/VendorPanel/storeStats/BrochureRequest";
import CustomerDetail from "./pages/VendorPanel/storeStats/CustomerDetail";
import ProtectedRoute from './pages/Routers/ProtectedRoute';
import ChooseScheme from "./pages/VendorPanel/manageSchemes/ChooseScheme";
import AddBanner from "./pages/VendorPanel/addBanner/AddBanner";
import AddAdvertisement from "./pages/VendorPanel/addAdvertisement/AddAdvertisement";
import SignUp from "./pages/auth/SignUp";
import SignUpOtp from './pages/auth/SignUpOtp';
import ManageMetal from './pages/VendorPanel/manageMetal/ManageMetal'
import ManageGemstone from "./pages/VendorPanel/manageGemstone/ManageGemstone";
import SignInOtp from "./pages/auth/SignInOtp";
import ManageProductCategory from "./pages/VendorPanel/manageProductCategory/ManageProductCategory";
import ManageProductOffer from "./pages/VendorPanel/manageProductOffer/ManageProductOffer";
import ManageOrders from "./pages/VendorPanel/manageOrders/ManageOrder";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<SignUp />} /> */}
        {/* <Route path='/SignUpOtp' element={<SignUpOtp />} /> */}
        <Route path='/' element={<Login />} />
        <Route path='/LoginOtp' element={<SignInOtp />} />
        <Route
          path="/vendorDashboard"
          element={
            <ProtectedRoute>
              <VendorDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorProfile"
          element={
            <ProtectedRoute>
              <VendorProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Orders"
          element={
            <ProtectedRoute>
              <ManageOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorProductsOffer"
          element={
            <ProtectedRoute>
              <ManageProductOffer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorStore"
          element={
            <ProtectedRoute>
              <MyStore />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorAdmin"
          element={
            <ProtectedRoute>
              <ManageAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorProducts"
          element={
            <ProtectedRoute>
              <ManagePhysicalGold />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorMetal"
          element={
            <ProtectedRoute>
              <ManageMetal />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorGemstone"
          element={
            <ProtectedRoute>
              <ManageGemstone />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendorProductsCategory"
          element={
            <ProtectedRoute>
              <ManageProductCategory />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/vendorScheme"
          element={
            <ProtectedRoute>
              <ManageSchemes />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/vendorStats"
          element={
            <ProtectedRoute>
              <Stats />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/vendorBrochure"
          element={
            <ProtectedRoute>
              <AddBrochure />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CreateScheme"
          element={
            <ProtectedRoute>
              <CreateScheme />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/JewellerySold"
          element={
            <ProtectedRoute>
              <JewellerySold />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Jewellery"
          element={
            <ProtectedRoute>
              <JewelleryDetail />
            </ProtectedRoute>
          }
        />
         <Route
          path="/brandEndoresment"
          element={
            <ProtectedRoute>
              <AddBanner />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/SchemesSold"
          element={
            <ProtectedRoute>
              <SchemesSold />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Schemes"
          element={
            <ProtectedRoute>
              <SchemeDetail />
            </ProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/BrochureRequest"
          element={
            <ProtectedRoute>
              <BrochureRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ChooseScheme"
          element={
            <ProtectedRoute>
              <ChooseScheme />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddBanner"
          element={
            <ProtectedRoute>
              <AddBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddAdvertisement"
          element={
            <ProtectedRoute>
              <AddAdvertisement />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
