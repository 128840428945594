import React from 'react'
import { useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import logoAunest from './../../assets/images/LogoAunest.png'
import axios from 'axios';
import baseURL from '../authServices/Url';


const SignInOtp = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [loginForm, setLoginForm] = useState({
    otp: ''
  })
  const [loginFormErrors, setLoginFormErrors] = useState({
    otp: ''
  })
  const handleChange = (event) => {
    setLoginForm({
      ...loginForm, [event.target.name]: event.target.value
    })
    setLoginFormErrors({
      ...loginFormErrors, [event.target.name]: null
    })
  }
  const handleValidation = () => {
    const { otp } = loginForm
    const newErrors = {}

    if (!otp) {
      newErrors.otp = 'please enter otp'
    } else if (otp && otp.length > 6) {
      newErrors.otp = 'otp should be below 6 digits'
    }
    return newErrors

  }

  const handleSubmit = async () => {
    try {
      const handleValidationObject = handleValidation()
      const vendorToken1 = localStorage.getItem('accessToken');
      if (Object.keys(handleValidationObject).length > 0) {
        setLoginFormErrors(handleValidationObject);
      } else {
        setLoader(true);
        const userCredentials = {
          otp: loginForm.otp,
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const responseVendor = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/goldVendor/auth/SignIn/verify/phoneNumber`, userCredentials, requestOptions)
        localStorage.setItem('accessVendorToken', responseVendor?.data?.data?.goldVednorAccessToken);
        localStorage.setItem('goldVendorId', responseVendor.data.data.gold_vendor_id);


        if (responseVendor?.status === 200) {
          toast.success('Login Successful');
          setLoader(false);
          setLoginForm({
            ...loginForm,
            otp: ''
          });
          setLoginFormErrors({}); // Resetting errors after successful submission
          setTimeout(() => {
            navigate('/vendorProfile');
          }, 1000);

        } else {
          toast.error('User credentials are invalid');
          setLoader(false);
        }

      }
    } catch (error) {
      console.error('Error:', error);
      if (error?.response?.status === 401) {
        toast.error(`${error?.response?.data?.message}`);
        setLoader(false);

      }

      setLoader(false);

    };
  }


  return (
    <div>
      {" "}
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          transition={Zoom}
          delay={1500}
          limit={1}
        />
        <div className="login">
          <div className="logo-container2">
            <div className=" logo-container">
              <img src={logoAunest} alt="" />
            </div>
          </div>

          <h2 className="login-header">Enter OTP</h2>

          <p>
            <input
              type="text"
              placeholder="Enter OTP"
              name='otp'
              autoComplete="off"
              value={loginForm.otp}
              onChange={handleChange}
            />
            <span className="text-danger" >{loginFormErrors.otp}</span>
          </p>
          <p
            onClick={handleSubmit}

          >

            {
              loader == true ? '' :
                <input
                  type="submit"
                  value="Log In"

                />
            }

            {
              loader == true && <div style={{ marginLeft: '170px' }} >
                <Spinner animation="border" variant="Primary" />
              </div>
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInOtp