import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { toast } from "react-toastify";
import AuthServices from '../../authServices/AuthServices'
import baseURL from '../../authServices/Url';

function ManageMetal() {
  const [metalModal, setMetalModal] = useState(false)
  const [productList, setProductList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('')
  const [physicalGoldForm, setPhysicalGoldForm] = useState({
    description: selectedProduct?.metal_description,
    priceUnit: selectedProduct?.metal_purity_unit,
  })
  const [physicalGoldFormErrors, setPhysicalGoldFormErrors] = React.useState({
    name: '',
    purity: '',
    description: '',
    purityUnit: '',
    weightUnit: '',
    priceUnit: '',
  })
  const [metalSelected, setMetalSelected] = useState('')
  const [purity, setPurity] = useState('')
  const metalData = ['Gold', 'Silver', 'Platinum']

  const purityData = {
    Gold: [24, 22, 18, 14],
    Silver: [999, 925],
    Platinum: [950]
  }

  const handleMetalChange = (e) => {
    setMetalSelected(e.target.value)
  }
  const handlePurityChange = (e) => {
    console.log('e.target.value', e.target.value)
    setPurity(e.target.value)
  }
  const vendorToken1 = localStorage.getItem('accessVendorToken');
  const vendorIdGold = localStorage.getItem('goldVendorId');

  const metalsAvailable = async () => {
    const getMetal = await axios.get(`https://aunest-backend.moshimoshi.cloud/api/v1/metal/readMetal?gold_vendor_id=${vendorIdGold}`)
    console.log('getMetal@@@@@@@@@@@@@@@@@@@', getMetal)
    if (getMetal?.status === 200) {
      console.log('getMetal@@@@@@@@@@@@@@@@@@@', getMetal?.data?.data)
      setProductList(getMetal?.data?.data)
      console.log('productList', productList);
    } else {
      console.log('error',);
      // toast.show({
      //   type: 'error',
      //   text1: `${getMetal.message}`,
      // })
    }
  }
  
  useEffect(() => {
    metalsAvailable();
  }, [])

  const handleChange = (e) => {
    setPhysicalGoldForm({
      ...physicalGoldForm,
      [e?.target?.name]: e?.target?.value,
      [e.target.purity]: e.target.value,
      [e.target.description]: e.target.description,
      [e.target.purityUnit]: e.target.value,
      [e.target.weightUnit]: e.target.value,
      [e.target.priceUnit]: e.target.value,
    });

    setPhysicalGoldFormErrors({
      ...physicalGoldFormErrors,
      [e.target.name]: null,
      [e.target.description]: null,
      [e.target.purity]: null,
      [e.target.purityUnit]: null,
      [e.target.weightUnit]: null,
      [e.target.priceUnit]: null,
    });
  };

  const handleValidation = () => {
    const { description, priceUnit } = physicalGoldForm
    const newErrors = {}

    if (!metalSelected) {
      newErrors.name = 'please select metal'
    }
    if (!purity) {
      newErrors.purity = 'please select purity'
    }
    if (!description) {
      newErrors.description = 'please enter description'
    }
    // if (!purityUnit) {
    //   newErrors.purityUnit = 'please enter Purity Unit'
    // }
    // if (!weightUnit) {
    //   newErrors.weightUnit = 'please enter weight Unit'
    // }
    if (!priceUnit) {
      newErrors.priceUnit = 'please enter Price/gram'
    }

    return newErrors
  }

  const handleSubmit = async () => {
    try {
      const handleValidationObject = handleValidation()

      if (Object.keys(handleValidationObject).length > 0) {
        setPhysicalGoldFormErrors(handleValidationObject)
      } else {

        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          'metal_name': `${metalSelected} ${purity}`,
          'metal_purity': parseInt(purity),
          'metal_description': physicalGoldForm?.description,
          'metal_purity_unit': metalSelected === 'Gold' ? `CARAT`:`PPT`,
          'metal_weight_unit': 'GRAMS',
          'metal_price_per_weight_unit': parseInt(physicalGoldForm?.priceUnit)
        })

        const physicalGold = await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/metal/createMetal`, userCredentials, requestOptions)
        if (physicalGold?.status === 200) {
          setPhysicalGoldForm({
            ...physicalGoldForm,
            description: '',
            priceUnit: '',
          })
          setMetalSelected()
          setPurity()
          handleCloseModal()
          metalsAvailable()
          toast.success(`metal succesfully created`);

        } else {
          console.log(physicalGold, "error after submit")
          toast.show({
            type: 'error',
            text1: `${physicalGold.message}`,
          })
          setPhysicalGoldForm({
            ...physicalGoldForm,
            description: '',
            priceUnit: '',
          })
          setMetalSelected()
          setPurity()
          handleCloseModal()
        }
      }
    } catch (e) {
      console.log('error ==>', e)
      if (e?.response?.status === 400) {
        setPhysicalGoldForm({
          ...physicalGoldForm,
          description: '',
          priceUnit: '',
        })
        setMetalSelected()
        setPurity()
        handleCloseModal()
        toast.error(`${e?.response?.data?.message}`);
      }
    }
  }

  const handleSubmitEdit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setPhysicalGoldFormErrors(handleValidationObject)
    } else {
      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };
      const userCredentials = {
        'metal_name': `${metalSelected} ${purity}`,
        'metal_purity': parseInt(purity),
        'metal_description': physicalGoldForm?.description,
        'metal_purity_unit': metalSelected === 'Gold' ? `CARAT`:`PPT`,
        'metal_weight_unit': 'GRAMS',
        'metal_price_per_weight_unit': physicalGoldForm?.priceUnit,
        'metal_id': selectedProduct?.id,
      }

      const EditPhysicalGold = await axios.put(`https://aunest-backend.moshimoshi.cloud/api/v1/metal/updateMetal`, userCredentials, requestOptions)
      console.log('EditPhysicalGold EditPhysicalGold', EditPhysicalGold);
      if (EditPhysicalGold?.status === 200) {
        toast.success('Product has successfully updated')
        metalsAvailable()
        setPhysicalGoldForm({
            ...physicalGoldForm,
            description: '',
            priceUnit: '',
          })
          setMetalSelected()
          setPurity()

        handleCloseModal()
      } else {
        console.log(EditPhysicalGold, "error after submit")
        toast.show({
          type: 'error',
          text1: `${EditPhysicalGold.message}`,
        })
        setPhysicalGoldForm({
          ...physicalGoldForm,
          description: '',
          priceUnit: '',
        })
        setMetalSelected()
        setPurity()
        setSelectedProduct()
        handleCloseModal()
      }


    }
  }

  const handleCloseModal = () => setMetalModal(false)
  const handleShowModal = (prod) => {
    console.log('prod', prod);
    setMetalModal(true)
    console.log('prod?.metal_name?.split()', prod?.metal_name?.split(' ')[0])
    setMetalSelected(prod?.metal_name?.split(' ')[0])
    setPurity(prod?.metal_purity)
    setSelectedProduct(prod)
    setPhysicalGoldForm({
      name: prod?.metal_name,
      purity: prod?.metal_purity,
      description: prod?.metal_description,
      purityUnit: prod?.metal_purity_unit,
      weightUnit: prod?.metal_weight_unit,
      priceUnit: prod?.metal_price_per_weight_unit,
    })

  }

  const handleShowModalDelete = (prod) => {
    setDeleteId(prod?.id)
    setIsOpen(true);

  };

  const permanentDeleteMetal = async () => {

    const requestOptions = {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${vendorToken1}`,
      },
    };

    const delProduct = await axios.delete(`https://aunest-backend.moshimoshi.cloud/api/v1/metal/permanentDelete?metal_id=${deleteId}`, requestOptions)
    if (delProduct?.status === 200) {
      setIsOpen(false)
      metalsAvailable()
      toast.success(`metal succesfully got deleted`);
    } else {
      console.log('error', delProduct?.message)
    }
  }
  
  const inActivateMetal = async (prod) => {
    console.log('ppppppppprod', prod)
    const userCredentials = {
      'gold_vendor_id': vendorIdGold,
      'product_category_id': '',
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
        'Content-Type': 'application/json',
      },
    };
    const delProduct = await axios.delete(`${baseURL}/metal/deleteMetal`, userCredentials, requestOptions)
    console.log('delProduct delProduct', delProduct)
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  
  return (
    <div>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content">
        <div className="container">
          <FirstNavbar />
          <h3 className='headertext'>Metal</h3>
          <div>
            <Card className='p-2'>
              <Row>
                <Col md={4}>
                  <h3 className='headertext'>Manage Metal:</h3>
                </Col>

              </Row>
              <Row>
                <Col md={3}>
                </Col>
                <Col md={3}>
                  <Button variant="warning" onClick={handleShowModal}>Add Metal</Button>
                </Col>
              </Row>

              <hr />
              <Card className='p-2'>
                <h3 className='text1'>Available Metals:</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Metal Name</th>
                      <th>Purity</th>
                      <th>Purity Unit</th>
                      <th>weight Unit</th>
                      <th>Price/gram</th>
                      <th>Description</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((prod, i) => {
                      console.log(prod);
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{prod?.metal_name}</td>

                          <td>{prod?.metal_purity}</td>
                          <td>{prod?.metal_purity_unit}</td>
                          <td>{prod?.metal_weight_unit}</td>
                          <td>{prod?.metal_price_per_weight_unit}</td>
                          <td>{prod?.metal_description}</td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModal(prod)}
                              icon={faEdit} className="editIcon"
                            />

                          </td>
                          <td>
                            <FontAwesomeIcon
                              onClick={() => handleShowModalDelete(prod)}
                              icon={faTrash} className="editIcon"
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={metalModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className='headertext text-center'>Add/Edit Metal Details:</h4>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Metal</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Metal"
                    size={"sm"}
                    className="selectsizesmall"
                    name="handleGenderChange"
                    onChange={(e) => handleMetalChange(e)}
                    value={metalSelected }
                  >

                    <option >Select Metal</option>
                    {metalData?.map((met, i) => {
                      return (
                        <option key={i} name='metal' value={met}>{met}</option>
                      )
                    })}
                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              {metalSelected && <Form.Group className="mb-3">
                <Form.Label>Select Purity</Form.Label>
                <div className="leftedge d-flex justify-content-space">
                  <Form.Select
                    aria-label="select Purity"
                    size={"sm"}
                    className="selectsizesmall"
                    onChange={(e) => handlePurityChange(e)}
                    value={purity}
                  >

                    <option >Select Purity</option>
                    {purityData[metalSelected]?.map((purity, i) => {
                      console.log('purrrrrity',purity);
                      return (
                        <option key={i} name='purity' value={purity}>{metalSelected === 'Gold' ? `${metalSelected} ${purity} k` : `${metalSelected} ${purity}`}</option>
                      )
                    })}

                  </Form.Select>
                </div>
                <span className="text-danger">{physicalGoldFormErrors?.purity}</span>
              </Form.Group>
              }
            </Col>
            {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Name"
                  size="sm"
                  name='name'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  // pattern="/^[a-zA-Z]*$/"
                  required
                  value={physicalGoldForm?.name}
                />
                <span className="text-danger">{physicalGoldFormErrors?.name}</span>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Purity</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Purity"
                  size="sm"
                  name='purity'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.purity}
                />
                <span className="text-danger">{physicalGoldFormErrors?.purity}</span>
              </Form.Group>


            </Col> */}
          </Row>
          <Row>

            {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Purity Unit</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Purity Unit"
                  size="sm"
                  name='purityUnit'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.purityUnit}
                />
                <span className="text-danger">{physicalGoldFormErrors?.purityUnit}</span>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Price/gram</Form.Label>
                <Form.Control
                  maxLength={50}
                  type="text"
                  placeholder="Enter Price/gram"
                  size="sm"
                  name='priceUnit'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.priceUnit}
                />
                <span className="text-danger">{physicalGoldFormErrors?.priceUnit}</span>
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  // maxLength={50}
                  // type="text"
                  as="textarea" rows={3}
                  placeholder="Enter description"
                  size="md"
                  name='description'
                  onChange={handleChange}
                  autoComplete='off'
                  className='mb-3'
                  value={physicalGoldForm?.description}
                />
                <span className="text-danger">{physicalGoldFormErrors?.description}</span>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={() => { selectedProduct?.id ? handleSubmitEdit() : handleSubmit() }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        onHide={closeModal}

      >
        <Modal.Body>
          <h2>Alert</h2>
          <p>Are you sure you want to delete the metal ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          {/* <Button variant="warning" onClick={inActivateMetal}>Inactivate</Button> */}
          <Button variant="danger" onClick={() => permanentDeleteMetal()}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default ManageMetal