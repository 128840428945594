import React from 'react'
import { BsSpeedometer, BsPersonCircle } from 'react-icons/bs'
import { BiStore } from 'react-icons/bi'
import { GiMetalBar } from "react-icons/gi";
import { IoDiamondOutline } from "react-icons/io5";
import { TbCategory } from "react-icons/tb";
import { MdOutlineLocalOffer } from "react-icons/md";
import { GiCubeforce } from "react-icons/gi";
import { GiPearlNecklace } from "react-icons/gi";
import { SiBrandfolder } from "react-icons/si";
import { Link } from "react-router-dom";
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import logoAunest from '../../assets/images/LogoAunest.png'



function SideBar({ Navigation }) {

  const handleClick = (e) => {
    Navigation.navigate(`/list_out`, {
      data: e
    })
  }



  const VendorScreens = [
    // {
    //   path: '/vendorDashboard',
    //   name: 'Dashboard',
    //   icon: <BsSpeedometer className="icons" />
    // },fa
    {
      path: '/vendorProfile',
      name: 'Profile',
      icon: <BsPersonCircle className="" />
    },
    {
      path: '/vendorProductsCategory',
      name: 'Products Category',
      icon: <TbCategory className="icons" />
    },
    {
      path: '/vendorStore',
      name: 'My Store',
      icon: <BiStore className="icons" />
    },

    {
      path: '/vendorMetal',
      name: 'Metal',
      icon: <GiMetalBar className="icons" />
    },
    {
      path: '/vendorGemStone',
      name: 'Gemstone',
      icon: <IoDiamondOutline className="icons" />
    },
    
    {
      path: '/vendorProductsOffer',
      name: 'Products Offers',
      icon: <MdOutlineLocalOffer className="icons" />
    },
    {
      path: '/vendorProducts',
      name: 'Products',
      icon: <GiPearlNecklace className="icons" />
    },

    {
      path: '/Orders',
      name: 'Orders',
      icon: <GiCubeforce className="icons" />
    },
    {
      path: '/brandEndoresment',
      name: 'Brand Endoresment',
      icon: <SiBrandfolder className="icons" />
    },

  ]

  return (
    <div>
      <div className="logo-container1">
        <img src={logoAunest} width="100%" height="100" className="m-auto" style={{ height: 'auto' }} />
      </div>
      <div>
        <>
          {VendorScreens.map((nav, i) => {
            return (
              <p key={i} class="cursor fs-6 fw-bold ">
                <Link to={nav?.path} className="p-1">
                  {nav?.icon} <span>{` ${nav?.name}`}</span>
                </Link>
              </p>
            )
          })}
        </>
      </div>
    </div>
  );
}


export default SideBar;
