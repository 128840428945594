import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../authServices/Url";


function VendorProfile() {
  const [profileModal, setProfileModal] = useState(false);
  const [profileModal1, setProfileModal1] = useState(false);
  const [vendorData, setVendorData] = useState()
  const [logoImg, setLogoImg] = useState('')
  const [bannersImg, setBannersImg] = useState([])
  const [editProf, setEditProf] = useState(false)
  const [editProp, setEditProp] = useState(false)

  const [userDetails, setUserDetails] = useState({
    name: "" || vendorData?.gold_vendor_name,
    email: ""  || vendorData?.gold_vendor_name,
    phone: "" || vendorData?.gold_vendor_name,
    gstNumber: "" || vendorData?.gold_vendor_name,
    addressStreet: '' || vendorData?.gold_vendor_name,
    addressPincode: '' || vendorData?.gold_vendor_name,
    addressCity: '' || vendorData?.gold_vendor_name,
    addressState: '' || vendorData?.gold_vendor_name,
    constitutionBusiness: '' || vendorData?.gold_vendor_name,

  });
  const [userDetailsError, setUserDetailsError] = useState({
    name: "",
    email: "",
    phone: "",
    gstNumber: "",
    addressStreet: '',
    addressPincode: '',
    addressCity: '',
    addressState: '',
    constitutionBusiness: '',

  });

  const [vendorProprietor, setVendorProprietor] = useState({
    proprietorName: "" || vendorData?.gold_vendor_name,
    proprietorDescription: "",
    proprietorLogo: "",
    proprietorBanners: '',
  });
  const [vendorProprietorError, setVendorProprietorError] = useState({
    proprietorName: '',
    proprietorDescription: '',
    proprietorLogo: '',
    proprietorBanners: '',
  });
  const vendorToken1 = localStorage.getItem('accessVendorToken');
  const vendorIdGold = localStorage.getItem('goldVendorId');

  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = () =>{ 
    setProfileModal(true)
    setEditProf(true)
  };
  const handleShowModal1 = () => {
    setProfileModal1(true)
    setEditProp(true)
  };
  const handleCloseModal1 = () => setProfileModal1(false);
  const [image, setImage] = useState(userDetails.image);
const [img, setImg]= useState([])
  const [imageErrors, setTmageErrors] = useState("");

  const vendorDataAvailable = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    const getVendorData = await axios.get(`https://aunest-backend.moshimoshi.cloud/api/v1/goldVendor/readParticular?gold_vendor_id=${vendorIdGold}`, requestOptions)
    if (getVendorData?.status === 200) {
      setVendorData(getVendorData?.data?.data)
    } else {
      console.log('error ====>', getVendorData)
    }
  }

  useEffect(() => {
    vendorDataAvailable()
  }, [])

  useEffect(() => {
    if (image !== "") {
      setTmageErrors("");
    }
  }, [image]);

  const handleChange = (e) => {
    console.log('eeeeeeeeeeeeeeee', e);
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
      [e.target.gstNumber]: e.target.value,
      [e.target.email]: e.target.value,
      [e.target.phone]: e.target.value,
      [e.target.addressStreet]: e.target.value,
      [e.target.addressPincode]: e.target.value,
      [e.target.addressCity]: e.target.value,
      [e.target.addressState]: e.target.value,
      [e.target.constitutionBusiness]: e.target.value,

    });

    setUserDetailsError({
      ...userDetailsError,
      [e.target.name]: null,
      [e.target.email]: null,
      [e.target.phone]: null,
      [e.target.gstNumber]: null,
      [e.target.addressStreet]: null,
      [e.target.addressPincode]: null,
      [e.target.addressCity]: null,
      [e.target.addressState]: null,
      [e.target.constitutionBusiness]: null,
    });
  };

  const handleChangeProprietor = (e) => {
    console.log('eeeeeeeeeeeeeeee', e.target.name);
    setVendorProprietor({
      ...vendorProprietor,
      [e.target.name]: e.target.value,
      // [e.target.proprietorDescription]: e.target.value,
      // [e.target.proprietorLogo]: e.target.value,
      // [e.target.proprietorBanners]: e.target.value,
    });

    console.log('vendorProprietor', vendorProprietor);
    setVendorProprietorError({
      ...vendorProprietorError,
      [e.target.proprietorName]: null,
      [e.target.proprietorDescription]: null,
      [e.target.proprietorLogo]: null,
      [e.target.proprietorBanners]: null,
    });
  };

  const venderValidation = () => {
    const newErrors = {};
    const { name, gstNumber, addressStreet, addressPincode, addressCity, addressState, constitutionBusiness } = userDetails;


    const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    if (name === "" || nameRegex.test(nameRegex)) {
      newErrors.name = "please enter name";
    }

    if (!gstNumber) {
      newErrors.gstNumber = "please enter GST Number ";
    }
    if (!addressStreet) {
      newErrors.addressStreet = "please enter Address Street";
    }
    if (!addressPincode) {
      newErrors.addressPincode = "please enter Address Pincode";
    }
    if (!addressCity) {
      newErrors.addressCity = "please enter Address City";
    }
    if (!addressState) {
      newErrors.addressState = "please enter Address City";
    }
    if (!constitutionBusiness) {
      newErrors.constitutionBusiness = "please enter Constitution Business";
    }
    return newErrors;
  };

  const venderProprietorValidation = () => {
    const newErrors = {};
    const { proprietorName, proprietorDescription,} = vendorProprietor;

    if (!proprietorName) {
      newErrors.proprietorName = "please enter name";
    }
    if (!proprietorDescription) {
      newErrors.proprietorDescription = "please enter Description";
    }
    if (logoImg === '') {
      newErrors.proprietorLogo = "please upload proprietor Logo";
    }
    if (bannersImg?.length === 0) {
      newErrors.proprietorBanners = "please upload proprietor Banners ";
    }

    return newErrors;
  };
  
  const handleImageChange = (e) => {
    const image = e.target.files;
    console.log('@@@@@@@@@@@@@@@', image?.length);
    setBannersImg([...image]);
  };

  const addVendor = async () => {
    const handleValidationObject = venderValidation();

    if (Object.keys(handleValidationObject).length > 0) {
      console.log('not working', Object.keys(handleValidationObject));
      setUserDetailsError(handleValidationObject)
    } else {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          "gold_vendor_name": userDetails?.name,
          "gold_vendor_gst_number": userDetails?.gstNumber,
          "gold_vendor_address": userDetails?.addressStreet,
          "gold_vendor_address_pincode": userDetails?.addressPincode,
          "gold_vendor_address_city": userDetails?.addressCity,
          "gold_vendor_address_state": userDetails?.addressState,
          "gold_vendor_constitution_of_business": userDetails?.constitutionBusiness,
        })
        const data = await axios.post(`${baseURL}/goldVendor/createGoldVendor`, userCredentials, requestOptions)

        console.log("profile updated ", data);

        if (data.status === 200) {
          toast.success(" Profile Details Updated", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "light",
          });
          handleCloseModal();

          // getUserDetails();
        } else {
          console.log("else is working");
        }
      } catch (e) {
        console.log('error ===>', e);
      }
    }
  };

  const logoImageUpload = async () => {
    var formdata = new FormData();
    formdata.append("image", logoImg);
    console.log('image userCredentials', logoImg)
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    return await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/mediaMingle/upload`, formdata, requestOptions)
    // console.log('imgUpload imgUpload imgUpload', imgUpload)
    // if (imgUpload?.status === 200) {
    //   console.log('selectedProduct?.id', imgUpload?.data?.data?.url)
    //   // selectedProduct?.id ? handleSubmitEdit(imgUpload?.data?.data?.url) : handleSubmit(imgUpload?.data?.data?.url)
    //   // handleSubmitEdit(imgUpload?.data?.data?.url)
    // }
  }

  const bannerImagesUpload = async (id) => {
    var formdata = new FormData();
    for (let i = 0; i < img?.length; i++) {
      formdata?.append("images", img[i]);
    }
    formdata.append("image", img);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${vendorToken1}`,
      },
    };
    return await axios.post(`https://aunest-backend.moshimoshi.cloud/api/v1/mediaMingle/upload/multiple`, formdata, requestOptions)
    // console.log('imgsUpload imgsUpload imgsUpload', imgsUpload)
    // const extensions = imgsUpload?.data?.data?.url?.map(x => x?.split('.').pop())
    // if (imgsUpload?.status === 200) {
    //   console.log('selectedProduct?.id', imgsUpload?.data?.data?.url)
    //   // handleSubmitEdit({ id, images: imgsUpload?.data?.data?.url?.slice(0, extensions.indexOf('mp4')),  })
    //   setVendorProprietor({...vendorProprietor, proprietorLogo: imgsUpload?.data?.data?.url})
    // }
  }

  const addVendorProprietor = async () => {
    const venderValidationObject = venderProprietorValidation();
    if (Object.keys(venderValidationObject).length > 0) {
      console.log("if working", Object.keys(venderValidationObject).length);
      setVendorProprietorError(venderValidationObject);
    } else {
      const [firstDataResponse, secondDataResponse] = await Promise.all([
        logoImageUpload(),
        bannerImagesUpload(),
      ]);
      console.log('firstDataResponse', firstDataResponse)
      console.log('secondDataResponse', secondDataResponse)
      if (firstDataResponse?.status === 200 && secondDataResponse?.status === 200) {
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };

      const userCredentials = JSON.stringify({
        "gold_vendor_proprietor_name": vendorProprietor?.proprietorName,
        "gold_vendor_description": vendorProprietor?.proprietorDescription,
        "gold_vendor_logo": firstDataResponse?.data?.data?.url,
        "gold_vendor_banners": secondDataResponse?.data?.data?.url,
      })
      const data = await axios.post(`${baseURL}/goldVendor/createGoldVendorProfile`, userCredentials, requestOptions)

      console.log("profile updated ", data);

      if (data?.status === 200) {
        toast.success(" Profile Details Updated", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal1();

      } else {
        console.log("else is working");
      }}
    }
  };

  const editVendor = async () => {
    const handleValidationObject = venderValidation();

    if (Object.keys(handleValidationObject).length > 0) {
      console.log('not working', Object.keys(handleValidationObject));
      setUserDetailsError(handleValidationObject)
    } else {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${vendorToken1}`,
            'Content-Type': 'application/json',
          },
        };
        const userCredentials = JSON.stringify({
          "gold_vendor_name": userDetails?.name,
          "gold_vendor_gst_number": userDetails?.gstNumber,
          "gold_vendor_address": userDetails?.addressStreet,
          "gold_vendor_address_pincode": userDetails?.addressPincode,
          "gold_vendor_address_city": userDetails?.addressCity,
          "gold_vendor_address_state": userDetails?.addressState,
          "gold_vendor_constitution_of_business": userDetails?.constitutionBusiness,
        })
        const data = await axios.post(`${baseURL}/goldVendor/createGoldVendor`, userCredentials, requestOptions)

        console.log("profile updated ", data);

        if (data.status === 200) {
          toast.success(" Profile Details Updated", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "light",
          });
          handleCloseModal();

          // getUserDetails();
        } else {
          console.log("else is working");
        }
      } catch (e) {
        console.log('error ===>', e);
      }
    }
  };

  const editVendorProprietor = async () => {
    const venderValidationObject = venderProprietorValidation();
    if (Object.keys(venderValidationObject).length > 0) {
      console.log("if working");
      setVendorProprietorError(venderValidationObject);
    } else {

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${vendorToken1}`,
          'Content-Type': 'application/json',
        },
      };

      const userCredentials = JSON.stringify({
        "gold_vendor_proprietor_name": vendorProprietor?.proprietorName,
        "gold_vendor_description": vendorProprietor?.proprietorDescription,
        "gold_vendor_logo": vendorProprietor?.proprietorLogo,
        // "gold_vendor_logo": `https://example.com/logo.png`,
        "gold_vendor_banners": vendorProprietor?.proprietorBanners,
        // "gold_vendor_banners": ["https://example.com/banner1.jpg", "https://example.com/banner2.jpg"],

      })
      const data = await axios.post(`${baseURL}/goldVendor/createGoldVendorProfile`, userCredentials, requestOptions)

      console.log("profile updated ", data);

      if (data.status === 200) {
        toast.success(" Profile Details Updated", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();

        // getUserDetails();
      } else {
        console.log("else is working");
      }
    }
  };

 

  return (
    <>
      <div>
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="content">
          <div className="container">
            <FirstNavbar />
            <h3 className="headertext">Profile</h3>
            {/* {userDetails.phone === "" ? (
              "...loading"
            ) : ( */}
            <div>
              <Card className="p-2">
                {/* <Row>
                  <Col md={6}>
                    <h3 className="headertext text1">Add Vendor Details:</h3>
                  </Col>
                  <Row>
                    <Col md={3}>
                      <Button variant="warning" onClick={handleShowModal}>
                        Add Profile
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button variant="warning" onClick={handleShowModal1}>
                        Add proprietor Profile
                      </Button>
                    </Col>
                  </Row>
                </Row> 
                <hr />*/}
                <Row>
                  <Col md={6}>
                    <h3 className="headertext text1">Vendor Profile Deatils:</h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={2}>
                    <Figure>
                      <Figure.Image
                        width={100}
                        height={100}
                        alt="171x180"
                        src={vendorData?.gold_vendor_logo}
                      />
                    </Figure>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={3}>
                    <h5>{vendorData?.gold_vendor_proprietor_name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={3}>
                    <h5>{vendorData?.gold_vendor_name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={3}>
                    <h5>{vendorData?.gold_vendor_gst_number}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={3}><h5>{vendorData?.gold_vendor_constitution_of_business}</h5></Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={6}>
                    <h5>{vendorData?.gold_vendor_address} {vendorData?.gold_vendor_address_city} {vendorData?.gold_vendor_address_state} {vendorData?.gold_vendor_address_pincode}</h5>
                  </Col>
                </Row>


                <Row>
                  <Col md={1}></Col>
                  <Col md={3}>

                    <h5>{vendorData?.gold_vendor_description}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    {vendorData?.gold_vendor_banners?.map((banner) => {
                      <div style={{ overflowX: 'scroll', justifyContent: 'space-evenly', alignItems: 'center', display: 'flex' }}>
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={100}
                            alt="171x180"
                            src={banner}
                          />
                        </Figure>
                      </div>
                    })}
                  </Col>
                  <Col md={1}></Col>
                </Row>
                {/* <hr />
                <Row>
                  <Col md={3}>
                    <Button variant="warning" onClick={handleShowModal}>
                      Edit Profile
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button variant="warning" onClick={handleShowModal1}>
                      Edit proprietor Profile
                    </Button>
                  </Col>
                </Row> */}
              </Card>
            </div>
          </div>
        </div>
        <Modal
          show={profileModal}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <h4 className="headertext text-center">Add Vendor Details:</h4>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Your Name"
                    size="sm"
                    name="name"
                    value={userDetails?.name}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                    pattern="/^[a-zA-Z]*$/"
                    required
                  />
                  <span className="text-danger">
                    {userDetailsError?.name}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Gst Number</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Your gst Number"
                    size="sm"
                    name="gstNumber"
                    value={userDetails?.gstNumber}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                    required
                  />
                  <span className="text-danger">
                    {userDetailsError?.gstNumber}
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter house no,street, area"
                    size="sm"
                    name="addressStreet"
                    value={userDetails?.addressStreet}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {userDetailsError?.addressStreet}
                  </span>
                </Form.Group>
                {/* {console.log(userDetails)} */}
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter your city"
                    size="sm"
                    name="addressCity"
                    value={userDetails?.addressCity}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {userDetailsError?.addressCity}
                  </span>
                </Form.Group>
                {/* {console.log(userDetails)} */}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>state</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter your State"
                    size="sm"
                    name="addressState"
                    value={userDetails?.addressState}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {userDetailsError?.addressState}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>pincode</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter your Pincode"
                    size="sm"
                    name="addressPincode"
                    value={userDetails?.addressPincode}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {userDetailsError?.addressPincode}
                  </span>
                </Form.Group>
                {/* {console.log(userDetails)} */}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Constitution Business</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter your constitution Business"
                    size="sm"
                    name="constitutionBusiness"
                    value={userDetails?.constitutionBusiness}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {userDetailsError?.constitutionBusiness}
                  </span>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal} variant="secondary">
              Cancel
            </Button>
            <Button variant="warning" onClick={addVendor}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={profileModal1}
          onHide={handleCloseModal1}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <h4 className="headertext text-center">Edit Proprietor Details:</h4>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Vendor Proprietor Name</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Your Name"
                    size="sm"
                    name="proprietorName"
                    value={vendorProprietor?.proprietorName}
                    onChange={handleChangeProprietor}
                    autoComplete="off"
                    className="mb-3"
                    required
                  />
                  <span className="text-danger">
                    {vendorProprietorError?.proprietorName}
                  </span>
                </Form.Group>
              </Col>

            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Logo</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="file"
                    placeholder="upload Logo"
                    size="sm"
                    name="proprietorLogo"
                    // value={vendorProprietor?.proprietorLogo}
                    onChange={(e)=>{console.log('eeee',e);setLogoImg(e.target.files[0])}}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {vendorProprietorError?.proprietorLogo}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Banner</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="file"
                    placeholder="upload Banner"
                    size="sm"
                    name="proprietorBanners"
                    // value={vendorProprietor?.proprietorBanners}
                    onChange={(e) => handleImageChange(e)}

                    autoComplete="off"
                    className="mb-3"
                    multiple
                  />
                  <span className="text-danger">
                    {vendorProprietorError?.proprietorBanners}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                   as="textarea" rows={3}
                    placeholder="Enter Your Description"
                    size="sm"
                    name="proprietorDescription"
                    value={vendorProprietor?.proprietorDescription}
                    onChange={handleChangeProprietor}
                    autoComplete="off"
                    className="mb-3"
                    required
                  />
                  <span className="text-danger">
                    {vendorProprietorError?.proprietorDescription}
                  </span>
                </Form.Group>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal1} variant="secondary">
              Cancel
            </Button>
            <Button variant="warning" onClick={addVendorProprietor}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    </>
  );
}

export default VendorProfile;
